import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RemoveIcon from '@mui/icons-material/Remove';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import Modals from '../../component/Modals';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

function ModifyStock() {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  // console.log(location);

  const { userId, user, role, Admins } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const id = params.stockId;

  // form data states
  const [customer, setCustomer] = React.useState({});
  const [stockType, setStockType] = React.useState('');
  const [recordedAt, setRecordedAt] = React.useState(new Date());

  // selection states
  const [customerId, setCustomerId] = React.useState('');
  const [customers, setCustomers] = React.useState([]);
  const [productsList, setProductsList] = React.useState([]);
  const [oldStock, setOldStock] = React.useState([]);

  // rendering triggers
  const [triggerA, setTriggerA] = React.useState(0);
  const [triggerB, setTriggerB] = React.useState(0);
  const [isDateError, setIsDateError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isFactory, setIsFactory] = React.useState(true);
  const [filter, setFilter] = React.useState('');

  // preview states
  const [showPreview, setShowPreview] = React.useState(false);
  const [includeZero, setIncludeZero] = React.useState(false);
  const [previewData, setPreviewData] = React.useState({});

  const handleSubmit = () => {
    setIsLoading(true);
    let navigateUrl = `/customers/stock/${customer?.id}`;
    let method = 'post';
    let url = '/api/v1/stock';
    let data = {
      customer: customer?.id,
      recordedBy: userId,
      type: stockType,
      products: [],
    };

    if (isFactory) {
      navigateUrl = '/factory';
      url += '/production';
      data.type = 'system';
      productsList?.forEach((p) => {
        data.recordedAt = recordedAt;
        if (p.intactQuantity > 0) {
          data.products.push({ ...p, quantity: p.intactQuantity });
        }
      });
    } else {
      if (id) {
        method = 'patch';
        url += `/${id}`;
      } else {
        data.recordedAt = recordedAt;
      }
      if (location?.state?.type === 'physical') {
        navigateUrl = '/physical-stocks';
      }
      productsList?.forEach((p) => {
        if (includeZero || p.intactQuantity > 0 || p.damagedQuantity > 0) {
          data.products.push(p);
        }
      });
    }

    if (!includeZero && !data.products.length) {
      toast.warn('Nothing to update!');
      return;
    }

    axiosPrivate({ method, url, data })
      .then((res) => {
        toast.success(res?.data?.message);
        navigate(navigateUrl);
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      })
      .finally(() => setIsLoading(false));
  };

  const handleChange = (id, type, value) => {
    const list = [];
    const previewList = [];

    let cartonsSumI = 0;
    let packetsSumI = 0;
    let cartonsSumD = 0;
    let packetsSumD = 0;

    productsList?.forEach((p) => {
      const px = { ...p };

      if (px.id === id) {
        switch (type) {
          case 'intactQuantity':
            if (value > 0) px.intactQuantity = value;
            else px.intactQuantity = 0;
            break;
          case 'damagedQuantity':
            if (value > 0) px.damagedQuantity = value;
            else px.damagedQuantity = 0;
            break;
          case 'damageDescription':
            px.damageDescription = value;
            break;
          case 'batchNo':
            px.batchNo = value;
            break;
          default:
            break;
        }
      }

      const cartonsI = Math.floor(px.intactQuantity / px.quantityPerCarton);
      const packetsI = px.intactQuantity % px.quantityPerCarton;
      cartonsSumI += cartonsI;
      packetsSumI += packetsI;
      let intactQuantityDisplay = `${cartonsI} ctn`;
      if (packetsI) {
        intactQuantityDisplay += ` ${packetsI} pcs`;
      }
      px.intactQuantityDisplay = intactQuantityDisplay;

      const cartonsD = Math.floor(px.damagedQuantity / px.quantityPerCarton);
      const packetsD = px.damagedQuantity % px.quantityPerCarton;
      cartonsSumD += cartonsD;
      packetsSumD += packetsD;
      let damagedQuantityDisplay = `${cartonsD} ctn`;
      if (packetsD) {
        damagedQuantityDisplay += ` ${packetsD} pcs`;
      }
      px.damagedQuantityDisplay = damagedQuantityDisplay;

      if (includeZero || px.intactQuantity > 0 || px.damagedQuantity > 0) {
        previewList.push(px);
      }
      list.push(px);
    });

    let intactQuantitySumDisplay = `${cartonsSumI} ctn`;
    if (packetsSumI) {
      intactQuantitySumDisplay += ` ${packetsSumI} pcs`;
    }

    let damagedQuantitySumDisplay = `${cartonsSumD} ctn`;
    if (packetsSumD) {
      damagedQuantitySumDisplay += ` ${packetsSumD} pcs`;
    }

    setPreviewData({
      products: previewList,
      hasProducts: Boolean(previewList.length > 0),
      intactQuantitySumDisplay,
      damagedQuantitySumDisplay,
    });

    setProductsList(list);
    setTriggerB(Date.now());
  };

  // Get old stock data
  React.useEffect(() => {
    setIsLoading(true);
    if (id) {
      axiosPrivate
        .get(`/api/v1/stock/${id}`)
        .then((res) => {
          const os = {};
          res?.data?.data?.products?.forEach((p) => {
            os[p?.product] = p;
          });
          setOldStock(os);
          setCustomerId(res?.data?.data?.customer);
          setStockType(res?.data?.data?.type);
          setRecordedAt(res?.data?.data?.recordedAt);
          setIsFactory(false);
          setTriggerA(Date.now());
        })
        .catch((err) => {
          toast.warn(
            `Failed to get stock data. ${err?.response?.data?.message}`,
          );
        });
    } else {
      setCustomerId(searchParams.get('customer'));
      setStockType(searchParams.get('type'));
      setIsFactory(Boolean(searchParams.get('type') === 'production'));
      setTriggerA(Date.now());
    }
  }, [id]);

  // Get all products
  React.useEffect(() => {
    setIsLoading(true);
    axiosPrivate
      .get(
        '/api/v1/product?populate=yes&limit=none&isActive=true&isOwn=true&sort=name',
      )
      .then((res) => {
        const allProducts = [];
        res?.data?.data?.forEach((p) => {
          const intactQuantity = oldStock[p?.id]?.intactQuantity || 0;
          const damagedQuantity = oldStock[p?.id]?.damagedQuantity || 0;
          const damageDescription = oldStock[p?.id]?.damageDescription || '';
          const batchNo = oldStock[p?.id]?.batchNo || '';
          const quantityPerCarton = p?.quantityPerCarton;

          const cartonsI = Math.floor(intactQuantity / quantityPerCarton);
          const packetsI = intactQuantity % quantityPerCarton;
          let intactQuantityDisplay = `${cartonsI} ctn`;
          if (packetsI) {
            intactQuantityDisplay += ` ${packetsI} pcs`;
          }

          const cartonsD = Math.floor(damagedQuantity / quantityPerCarton);
          const packetsD = damagedQuantity % quantityPerCarton;
          let damagedQuantityDisplay = `${cartonsD} ctn`;
          if (packetsD) {
            damagedQuantityDisplay += ` ${packetsD} pcs`;
          }

          allProducts.push({
            ...p,
            product: p?.id,
            intactQuantity,
            damagedQuantity,
            damageDescription,
            batchNo,
            intactQuantityDisplay,
            damagedQuantityDisplay,
          });
        });
        setProductsList(allProducts);
        setTriggerB(Date.now());
        setIsLoading(false);
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      });
  }, [triggerA]);

  // Get selected Customer data
  React.useEffect(() => {
    if (customerId) {
      axiosPrivate
        .get(`/api/v1/customer/${customerId}?populate=yes`)
        .then((res) => {
          setCustomer(res?.data?.data);
        })
        .catch((err) => {
          toast.warn(
            `Failed to get customer data. ${err?.response?.data?.message}`,
          );
        });
    }
  }, [customerId]);

  // Get all customers
  React.useEffect(() => {
    setCustomers([]);
    let url = `/api/v1/customer?populate=yes&limit=none&isActive=true&sort=code`;

    if (
      !['Super Admin', ...Admins, 'Accounts', 'DO', 'Factory'].includes(role)
    ) {
      url += `&parentTerritory=${user?.employee?.territory?.id}`;
    }
    axiosPrivate
      .get(url)
      .then((res) => {
        if (res?.data?.data) {
          setCustomers(res.data.data);
        }
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      });
  }, []);

  React.useEffect(() => {
    if (stockType !== 'physical') {
      setIncludeZero(false);
    }
  }, [stockType]);

  React.useEffect(() => {
    handleChange();
  }, [includeZero]);

  const productsControl = React.useMemo(() => {
    if (isLoading || productsList.length < 1) {
      return <Typography color="text.disabled">Loading products...</Typography>;
    }
    return productsList.map((p, i) => {
      if (
        filter &&
        p?.nameCode &&
        !p?.nameCode.toLowerCase().includes(filter.toLocaleLowerCase())
      ) {
        return null;
      }

      return (
        <Card key={p.id} variant="outlined">
          <Stack direction="column" gap={1} p={2}>
            <Typography fontWeight="bold">{`${i + 1}. ${
              p.nameCode
            }`}</Typography>

            {isFactory ? (
              <>
                <Divider sx={{ mb: 1 }} />

                <Stack
                  direction={{ sm: 'row', xs: 'column' }}
                  gap={0.5}
                  alignItems="center">
                  <Stack direction="row" gap={{ sm: 0, xs: 1 }}>
                    <Button
                      color="success"
                      size="small"
                      sx={btnWidth}
                      onClick={() => {
                        const q = p.intactQuantity - p.quantityPerCarton;
                        handleChange(p.id, 'intactQuantity', q);
                      }}>
                      -1 carton
                    </Button>
                    <IconButton
                      color="success"
                      aria-label="remove one"
                      onClick={() => {
                        const q = p.intactQuantity - 1;
                        handleChange(p.id, 'intactQuantity', q);
                      }}>
                      <RemoveIcon />
                    </IconButton>
                  </Stack>

                  <TextField
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    size="small"
                    label="Packets"
                    color="success"
                    fullWidth
                    helperText={p.intactQuantityDisplay}
                    value={p.intactQuantity}
                    onChange={(e) => {
                      const q = parseInt(e.target.value, 10);
                      handleChange(p.id, 'intactQuantity', q);
                    }}
                  />
                  <Stack direction="row" gap={{ sm: 0, xs: 1 }}>
                    <IconButton
                      color="success"
                      aria-label="add one"
                      onClick={() => {
                        const q = p.intactQuantity + 1;
                        handleChange(p.id, 'intactQuantity', q);
                      }}>
                      <AddIcon />
                    </IconButton>
                    <Button
                      color="success"
                      size="small"
                      sx={btnWidth}
                      onClick={() => {
                        const q = p.intactQuantity + p.quantityPerCarton;
                        handleChange(p.id, 'intactQuantity', q);
                      }}>
                      +1 carton
                    </Button>
                    <Button
                      color="success"
                      size="small"
                      sx={btnWidth}
                      onClick={() => {
                        const q = p.intactQuantity + p.quantityPerCarton * 10;
                        handleChange(p.id, 'intactQuantity', q);
                      }}>
                      +10 carton
                    </Button>
                  </Stack>
                </Stack>

                <TextField
                  type="text"
                  size="small"
                  label="Batch No."
                  color="success"
                  fullWidth
                  value={p.batchNo}
                  onChange={(e) => {
                    handleChange(p.id, 'batchNo', e.target.value);
                  }}
                />
              </>
            ) : (
              <>
                <Divider />

                <Typography sx={{ color: 'success.main' }} variant="subtitle2">
                  Intact count
                </Typography>

                <Stack
                  direction={{ sm: 'row', xs: 'column' }}
                  gap={0.5}
                  alignItems="center">
                  <Stack direction="row" gap={{ sm: 0, xs: 1 }}>
                    <Button
                      color="success"
                      size="small"
                      sx={btnWidth}
                      onClick={() => {
                        const q = p.intactQuantity - p.quantityPerCarton;
                        handleChange(p.id, 'intactQuantity', q);
                      }}>
                      -1 carton
                    </Button>
                    <IconButton
                      color="success"
                      aria-label="remove one"
                      onClick={() => {
                        const q = p.intactQuantity - 1;
                        handleChange(p.id, 'intactQuantity', q);
                      }}>
                      <RemoveIcon />
                    </IconButton>
                  </Stack>
                  <TextField
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    size="small"
                    label="Packets"
                    color="success"
                    fullWidth
                    helperText={p.intactQuantityDisplay}
                    value={p.intactQuantity}
                    onChange={(e) => {
                      const q = parseInt(e.target.value, 10);
                      handleChange(p.id, 'intactQuantity', q);
                    }}
                  />
                  <Stack direction="row" gap={{ sm: 0, xs: 1 }}>
                    <IconButton
                      color="success"
                      aria-label="add one"
                      onClick={() => {
                        const q = p.intactQuantity + 1;
                        handleChange(p.id, 'intactQuantity', q);
                      }}>
                      <AddIcon />
                    </IconButton>
                    <Button
                      color="success"
                      size="small"
                      sx={btnWidth}
                      onClick={() => {
                        const q = p.intactQuantity + p.quantityPerCarton;
                        handleChange(p.id, 'intactQuantity', q);
                      }}>
                      +1 carton
                    </Button>
                    <Button
                      color="success"
                      size="small"
                      sx={btnWidth}
                      onClick={() => {
                        const q = p.intactQuantity + p.quantityPerCarton * 10;
                        handleChange(p.id, 'intactQuantity', q);
                      }}>
                      +10 carton
                    </Button>
                  </Stack>
                </Stack>

                <Divider />

                <Typography sx={{ color: 'error.main' }} variant="subtitle2">
                  Damage count
                </Typography>

                <Stack
                  direction={{ sm: 'row', xs: 'column' }}
                  gap={0.5}
                  alignItems="center">
                  <Stack direction="row" gap={{ sm: 0, xs: 1 }}>
                    <Button
                      color="error"
                      size="small"
                      sx={btnWidth}
                      onClick={() => {
                        const q = p.damagedQuantity - p.quantityPerCarton;
                        handleChange(p.id, 'damagedQuantity', q);
                      }}>
                      -1 carton
                    </Button>
                    <IconButton
                      color="error"
                      aria-label="remove one"
                      onClick={() => {
                        const q = p.damagedQuantity - 1;
                        handleChange(p.id, 'damagedQuantity', q);
                      }}>
                      <RemoveIcon />
                    </IconButton>
                  </Stack>
                  <TextField
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    size="small"
                    label="Packets"
                    color="error"
                    fullWidth
                    helperText={p.damagedQuantityDisplay}
                    value={p.damagedQuantity}
                    onChange={(e) => {
                      const q = parseInt(e.target.value, 10);
                      handleChange(p.id, 'damagedQuantity', q);
                    }}
                  />
                  <Stack direction="row" gap={{ sm: 0, xs: 1 }}>
                    <IconButton
                      color="error"
                      aria-label="add one"
                      onClick={() => {
                        const q = p.damagedQuantity + 1;
                        handleChange(p.id, 'damagedQuantity', q);
                      }}>
                      <AddIcon />
                    </IconButton>
                    <Button
                      color="error"
                      size="small"
                      sx={btnWidth}
                      onClick={() => {
                        const q = p.damagedQuantity + p.quantityPerCarton;
                        handleChange(p.id, 'damagedQuantity', q);
                      }}>
                      +1 carton
                    </Button>
                    <Button
                      color="error"
                      size="small"
                      sx={btnWidth}
                      onClick={() => {
                        const q = p.damagedQuantity + p.quantityPerCarton * 10;
                        handleChange(p.id, 'damagedQuantity', q);
                      }}>
                      +10 cartons
                    </Button>
                  </Stack>
                </Stack>

                <TextField
                  type="text"
                  size="small"
                  label="Damage description"
                  color="error"
                  fullWidth
                  multiline
                  rows={2}
                  value={p.damageDescription}
                  onChange={(e) => {
                    handleChange(p.id, 'damageDescription', e.target.value);
                  }}
                />
              </>
            )}
          </Stack>
        </Card>
      );
    });
  }, [triggerB]);

  return (
    <Container maxWidth="sm">
      <IconButton
        aria-label="go back"
        sx={{ mb: 1 }}
        onClick={() => navigate(-1)}>
        <ArrowBackIcon />
      </IconButton>
      <Box>
        <Paper elevation={2} sx={{ p: 3 }}>
          <Stack gap={2}>
            {!isFactory && (
              <>
                <Autocomplete
                  fullWidth
                  disableClearable
                  disabled={id || customers?.length < 1}
                  options={customers}
                  value={customer}
                  isOptionEqualToValue={(option, value) =>
                    value?.id === option?.id
                  }
                  onChange={(event, value) => setCustomer(value)}
                  getOptionLabel={(option) =>
                    option?.name
                      ? `${option?.nameCode} - ${option?.territory?.name}`
                      : ''
                  }
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Customer" />
                  )}
                />

                <FormControl disabled={id}>
                  <FormLabel id="stock-type-options-label">
                    Stock type
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="stock-type-options-label"
                    value={stockType}
                    onChange={(e) => setStockType(e.target.value)}>
                    <FormControlLabel
                      value="opening"
                      control={<Radio />}
                      label="Opening"
                    />
                    <FormControlLabel
                      value="physical"
                      control={<Radio />}
                      label="Physical"
                    />
                    {id && (
                      <FormControlLabel
                        value="system"
                        control={<Radio />}
                        label="System"
                      />
                    )}
                  </RadioGroup>
                </FormControl>
              </>
            )}

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                disabled={id}
                label={isFactory ? 'Production Date' : 'Stock Date'}
                disableFuture
                inputFormat="DD/MM/YYYY"
                value={recordedAt}
                onError={(reason) => setIsDateError(Boolean(reason))}
                onChange={(newValue) => setRecordedAt(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}>
              <Typography variant="h6" color="primary">
                Product details
              </Typography>

              <TextField
                type="search"
                size="small"
                variant="outlined"
                placeholder="Search products"
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                  setTriggerB(Date.now());
                }}
              />
            </Stack>

            {productsControl}
          </Stack>
          {stockType === 'physical' ? (
            <FormControlLabel
              control={<Checkbox />}
              checked={includeZero}
              onChange={() => setIncludeZero(!includeZero)}
              label="Include zero values"
              sx={{ mt: 3 }}
            />
          ) : null}
        </Paper>
      </Box>

      <Box sx={{ my: 2, textAlign: 'center' }}>
        <Button
          disabled={isLoading || !customer?.id || !previewData.hasProducts}
          size="large"
          color="primary"
          variant="contained"
          onClick={() => setShowPreview(true)}>
          Preview
        </Button>
      </Box>

      <Modals
        title="Preview"
        show={showPreview}
        onSave={() => setShowPreview(false)}
        onHide={() => setShowPreview(false)}
        width="lg"
        form>
        {!isFactory && (
          <>
            <Typography variant="subtitle2" color="primary">
              Type:
            </Typography>
            <Typography gutterBottom textTransform="capitalize">
              {stockType}
            </Typography>
            <Typography variant="subtitle2" color="primary">
              Customer:
            </Typography>
            <Typography gutterBottom>{customer?.nameCode}</Typography>
            <Typography variant="subtitle2" color="primary">
              Stock Date:
            </Typography>
            <Typography gutterBottom>
              {recordedAt ? dayjs(recordedAt).format('DD/MM/YYYY') : ''}
            </Typography>
            <Typography variant="subtitle2" color="primary">
              Products:
            </Typography>
          </>
        )}

        <Stack direction={'column'} gap={2} sx={{ pb: 2 }}>
          {previewData?.products?.map((p, index) => {
            return (
              <Paper variant="outlined" sx={{ p: 1, minWidth: 360 }}>
                <Typography fontWeight="bold">
                  {`${index + 1}. ${p?.nameCode}`}
                </Typography>

                {isFactory ? (
                  <>
                    <Typography variant="subtitle2">
                      <b>Quantity :</b> {p?.intactQuantityDisplay}
                    </Typography>
                    <Typography variant="subtitle2">
                      <b>Batch No. :</b> {p?.batchNo}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="subtitle2">
                      <b>Intact :</b> {p?.intactQuantityDisplay}
                    </Typography>
                    <Typography variant="subtitle2">
                      <b>Damage :</b> {p?.damagedQuantityDisplay}
                    </Typography>
                    {p?.damageDescription && (
                      <Typography variant="subtitle2">
                        <b>Damage Description :</b> {p?.damageDescription}
                      </Typography>
                    )}
                  </>
                )}
              </Paper>
            );
          })}
        </Stack>

        {isFactory ? (
          <>
            <Typography variant="subtitle2" color="primary">
              Total Production:
            </Typography>
            <Typography gutterBottom>
              {previewData?.intactQuantitySumDisplay}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="subtitle2" color="primary">
              Total Intact Stock:
            </Typography>
            <Typography gutterBottom>
              {previewData?.intactQuantitySumDisplay}
            </Typography>
            <Typography variant="subtitle2" color="primary">
              Total Damaged Stock:
            </Typography>
            <Typography gutterBottom>
              {previewData?.damagedQuantitySumDisplay}
            </Typography>
          </>
        )}
        <br />
        <Button
          disabled={isLoading || isDateError}
          fullWidth
          size="large"
          color="primary"
          variant="contained"
          onClick={handleSubmit}>
          Save
        </Button>
      </Modals>
    </Container>
  );
}

export default ModifyStock;

const btnWidth = { maxWidth: 64 };
