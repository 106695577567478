import React, { useEffect, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Autocomplete,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  IconButton,
  Link as MuiLink,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuth from '../../../hooks/useAuth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useHeader from '../../../hooks/useHeader';
import roundToDigit from '../../../utils/roundToDigit';

function TaDaForm() {
  const axiosPrivate = useAxiosPrivate();
  const { state: x } = useLocation();
  const navigate = useNavigate();

  const { user } = useAuth();
  const { setHeader } = useHeader();

  const isFromRedirect = Boolean(x?.from === 'FORM');
  const employee = user?.employee;
  const exDealerVisitRef = x?.exDealerVisitRef;
  const dealerSearchRef = x?.dealerSearchRef;

  const travelReasonOptions = [
    'Existing dealer visit',
    'Dealer search visit',
    'Customer discussion',
    'Work with SO/SR',
    'Meeting purpose',
  ];

  const transportList = [
    'একাধিক বাহন',
    'রিক্সা',
    'বাস',
    'অটো রিক্সা',
    'সি এন জি',
    'ভেন',
    'ট্রেন',
    'লেগুনা',
    'টমটম',
    'মটর সাইকেল',
    'নৌকা',
  ];
  const [regionList, setRegionList] = useState([]);
  const [headquarterList, setHeadquarterList] = useState([]);
  const [pointList, setPointList] = useState([]);
  const [routeList, setRouteList] = useState([]);
  const [userTerritoryIDList, setUserTerritoryIDList] = useState([]);

  const [travelDate, setTravelDate] = useState(x?.travelDate || new Date());
  const [dearnessAllowance, setDearnessAllowance] = useState(
    user?.employee?.role?.inTerritoryAllowance,
  );
  const [isInTerritory, setIsInTerritory] = useState(true);
  const [hotelBill, setHotelBill] = useState('');
  const [hotelName, setHotelName] = useState('');
  const [hotelContact, setHotelContact] = useState('');
  const [otherBill, setOtherBill] = useState('');
  const [otherBillDescription, setOtherBillDescription] = useState('');
  const [region, setRegion] = useState(x?.region || {});
  const [headquarter, setHeadquarter] = useState(x?.headquarter || {});
  const [point, setPoint] = useState(x?.point || {});
  const [route, setRoute] = useState(x?.route || '');
  const [latitude, setLatitude] = useState(x?.latitude);
  const [longitude, setLongitude] = useState(x?.longitude);
  const [reasonEntries, setReasonEntries] = useState([]);
  const [travelEntries, setTravelEntries] = useState([
    {
      id: Date.now(),
      travelStart: '',
      travelEnd: '',
      transport: '',
      travelAllowance: '',
    },
  ]);
  const [orderQuantity, setOrderQuantity] = useState('');
  const [orderValue, setOrderValue] = useState(0);
  const [workingTime, setworkingTime] = useState('');
  const [totalTA, setTotalTA] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [isDateError, setIsDateError] = useState(false);

  const handleReasonChange = ({ target: { value } }) => {
    if (value) {
      const entries = [...reasonEntries];
      const index = entries.indexOf(value);

      if (index < 0) {
        entries.push(value);
      } else {
        entries.splice(index, 1);
      }

      setReasonEntries(entries);
    }
  };

  const handleTravelChange = (i, key, value) => {
    const entries = [...travelEntries];
    entries[i][key] = value;
    setTravelEntries(entries);
    setTrigger(Date.now());
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    const data = {
      employee: employee?.id,
      exDealerVisitRef: exDealerVisitRef,
      dealerSearchRef: dealerSearchRef,
      travelDate: travelDate,
      reasonForTravel: reasonEntries.join(', '),
      travelEntries: travelEntries,
      dearnessAllowance: dearnessAllowance,
      isInTerritory: isInTerritory,
      hotelBill: hotelBill,
      hotelName: hotelName,
      hotelContact: hotelContact,
      otherBill: otherBill,
      otherBillDescription: otherBillDescription,
      region: region?.id,
      headquarter: headquarter?.id,
      point: point?.id,
      route: route,
      latitude: latitude,
      longitude: longitude,
      orderQuantity,
      orderValue,
      workingTime,
    };
    axiosPrivate
      .post('/api/v1/travel-allowance/form', data)
      .then(() => {
        navigate('/tada-details', { state: null });
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    setHeader({ title: 'TA DA Form' });
  }, []);

  // Push reasonForTravel from from
  useEffect(() => {
    if (isFromRedirect) {
      setReasonEntries([...reasonEntries, x?.reasonForTravel]);
    }
  }, [isFromRedirect]);

  // get user territories id
  useEffect(() => {
    axiosPrivate
      .get(`/api/v1/territory/${user?.employee?.territory?.id}`)
      .then((res) => {
        const list = [];
        res?.data?.data?.children?.forEach((t) => list.push(t?.id));
        res?.data?.data?.parents?.forEach((t) => list.push(t?.id));

        setUserTerritoryIDList(list);
      })
      .catch((error) => console.error(error));
  }, [user?.employee?.territory?.id]);

  // get Region List by territory type
  useEffect(() => {
    axiosPrivate
      .get(
        '/api/v1/territory?populate=yes&limit=none&sort=name&territoryType=63aaa3c505e4dc7daa1a85ce',
      )
      .then((res) => {
        setRegionList(res?.data?.data);
      })
      .catch((error) => console.error(error));
  }, []);

  // get Headquarter List by it's parent territory id
  useEffect(() => {
    axiosPrivate
      .get(
        `/api/v1/territory?populate=yes&limit=none&sort=name&parentTerritory=${region?.id}`,
      )
      .then((res) => {
        setHeadquarterList(res?.data?.data);
      })
      .catch((error) => console.error(error));
  }, [region?.id]);

  // get Point List by it's parent territory id
  useEffect(() => {
    axiosPrivate
      .get(
        `/api/v1/territory?populate=yes&limit=none&sort=name&parentTerritory=${headquarter?.id}`,
      )
      .then((res) => {
        setPointList(res?.data?.data);
      })
      .catch((error) => console.error(error));
  }, [headquarter?.id]);

  // get Route List by it's parent territory id
  useEffect(() => {
    axiosPrivate
      .get(
        `/api/v1/territory?populate=yes&limit=none&sort=name&parentTerritory=${point?.id}`,
      )
      .then((res) => {
        setRouteList(res?.data?.data);
      })
      .catch((error) => console.error(error));
  }, [point?.id]);

  // set isInTerritory
  useEffect(() => {
    if (headquarter?.id) {
      if (userTerritoryIDList?.includes(headquarter?.id)) {
        setIsInTerritory(true);
      } else {
        setIsInTerritory(false);
      }
    } else {
      setIsInTerritory(true);
    }
  }, [headquarter?.id, userTerritoryIDList?.length]);

  // set in territory DA
  useEffect(() => {
    if (hotelBill > 0) {
      setDearnessAllowance(user?.employee?.role?.outStationAllowance);
    } else if (isInTerritory) {
      setDearnessAllowance(user?.employee?.role?.inTerritoryAllowance);
    } else {
      setDearnessAllowance(user?.employee?.role?.outTerritoryAllowance);
    }
  }, [hotelBill, isInTerritory]);

  // calculate total TA
  useEffect(() => {
    let c = 0;
    travelEntries.forEach((e) => {
      if (typeof e?.travelAllowance === 'number') {
        c += e?.travelAllowance;
      }
    });
    setTotalTA(roundToDigit(c));
  }, [trigger, travelEntries?.length]);

  useEffect(() => {
    if (!reasonEntries.includes('Work with SO/SR')) {
      setOrderQuantity('');
      setOrderValue(0);
      setworkingTime('');
    }
  }, [reasonEntries?.length]);

  const travelEntriesControl = useMemo(
    () =>
      travelEntries.map((v, i) => (
        <Paper key={v?.id} variant="outlined" sx={{ pt: 0.5, pb: 1, px: 1 }}>
          <Stack
            direction="row"
            sx={{
              gap: 1,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <Typography variant="subtitle2" color="text.secondary">
              {i + 1}.
            </Typography>

            <IconButton
              color="error"
              edge="end"
              size="small"
              disabled={travelEntries?.length <= 1}
              onClick={() => {
                const entries = [...travelEntries];
                entries.splice(i, 1);

                setTravelEntries(entries);
              }}>
              <ClearIcon />
            </IconButton>
          </Stack>

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            sx={{ gap: { xs: 2, sm: 1 }, pt: 1 }}>
            <TextField
              fullWidth
              type="text"
              size="small"
              label="From"
              value={v?.travelStart}
              onChange={(e) =>
                handleTravelChange(i, 'travelStart', e?.target?.value)
              }
            />

            <TextField
              fullWidth
              type="text"
              size="small"
              label="To"
              value={v?.travelEnd}
              onChange={(e) =>
                handleTravelChange(i, 'travelEnd', e?.target?.value)
              }
            />
          </Stack>

          <Stack direction="row" sx={{ gap: 1, pt: 2 }}>
            <TextField
              fullWidth
              type="number"
              size="small"
              label="Cost"
              value={v?.travelAllowance}
              onChange={(e) => {
                const v = roundToDigit(e?.target?.value);
                handleTravelChange(i, 'travelAllowance', v > 0 ? v : '');
              }}
              onWheel={(e) => e.target.blur()}
            />

            <TextField
              fullWidth
              select
              size="small"
              label="Transport"
              value={v?.transport}
              onChange={(e) =>
                handleTravelChange(i, 'transport', e?.target?.value)
              }>
              {transportList?.map((v) => (
                <MenuItem key={v} value={v}>
                  {v}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </Paper>
      )),
    [trigger, travelEntries?.length, transportList?.length],
  );

  return (
    <Container disableGutters={true} maxWidth="sm">
      <Stack sx={{ gap: 2 }}>
        <Paper sx={{ p: 2, pt: 3 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              disabled={true}
              label="Date of Travel"
              disableFuture
              inputFormat="DD/MM/YYYY"
              value={travelDate}
              onError={(reason) => setIsDateError(Boolean(reason))}
              onChange={(newValue) => setTravelDate(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>

          <FormControl component="fieldset" variant="standard" sx={{ mt: 2 }}>
            <FormLabel component="legend">Reasons For Travel</FormLabel>
            <FormGroup>
              {travelReasonOptions?.map((v) => (
                <FormControlLabel
                  key={v}
                  control={
                    <Checkbox
                      checked={reasonEntries.indexOf(v) !== -1}
                      disabled={
                        v === 'Existing dealer visit' ||
                        v === 'Dealer search visit' ||
                        (v === 'Customer discussion' && !exDealerVisitRef) ||
                        (v === 'Work with SO/SR' && !exDealerVisitRef) ||
                        (v === 'Meeting purpose' &&
                          (exDealerVisitRef || dealerSearchRef))
                      }
                      onChange={handleReasonChange}
                      value={v}
                    />
                  }
                  label={v}
                />
              ))}
            </FormGroup>
            {!exDealerVisitRef && !dealerSearchRef ? (
              <>
                <FormHelperText error>
                  You need to fill up Existing Dealer Form to be able to select
                  "Customer discussion" or "Work with SO/SR" option
                </FormHelperText>

                <MuiLink
                  component={Link}
                  to="/exdealerform"
                  underline="hover"
                  variant="subtitle1">
                  Fill up Existing Dealer Form
                </MuiLink>
              </>
            ) : null}
          </FormControl>
        </Paper>

        {reasonEntries.includes('Work with SO/SR') ? (
          <Stack component={Paper} sx={{ gap: 2, p: 2 }}>
            <Typography variant="subtitle1" color="text.secondary">
              Work Details
            </Typography>

            <TextField
              fullWidth
              select
              label="Order Quantity"
              value={orderQuantity}
              onChange={(e) => setOrderQuantity(e.target.value)}>
              <MenuItem value="10 ctn">10 ctn</MenuItem>
              <MenuItem value="20 ctn">20 ctn</MenuItem>
              <MenuItem value="30 ctn">30 ctn</MenuItem>
              <MenuItem value="40 ctn">40 ctn</MenuItem>
              <MenuItem value="50 ctn or above">50 ctn or above</MenuItem>
            </TextField>

            <TextField
              fullWidth
              label="Order Value (in taka)"
              type="number"
              value={orderValue}
              onChange={(e) => {
                const v = roundToDigit(e?.target?.value);
                setOrderValue(v > 0 ? v : '');
              }}
              onWheel={(e) => e.target.blur()}
            />

            <TextField
              fullWidth
              select
              label="Working Time"
              value={workingTime}
              onChange={(e) => setworkingTime(e.target.value)}>
              <MenuItem value="1 hr">1 hr</MenuItem>
              <MenuItem value="2 hr">2 hr</MenuItem>
              <MenuItem value="3 hr">3 hr</MenuItem>
              <MenuItem value="4 hr">4 hr</MenuItem>
              <MenuItem value="5 hr or above">5 hr or above</MenuItem>
            </TextField>
          </Stack>
        ) : null}

        <Stack component={Paper} sx={{ gap: 2, p: 2 }}>
          <Typography variant="subtitle1" color="text.secondary">
            Travel Location
          </Typography>

          <Autocomplete
            fullWidth
            disabled={isFromRedirect}
            options={regionList}
            value={region}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => {
              setRegion(value);
              setHeadquarter({});
              setPoint({});
              setHeadquarterList([]);
              setPointList([]);
            }}
            getOptionLabel={(option) => option?.name || ''}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Select Region" />
            )}
          />

          <Autocomplete
            fullWidth
            disabled={isFromRedirect || !region?.id}
            options={headquarterList}
            value={headquarter}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => {
              setHeadquarter(value);
              setPoint({});
              setPointList([]);
            }}
            getOptionLabel={(option) => option?.name || ''}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Select HQ" />
            )}
          />

          <Autocomplete
            fullWidth
            disabled={isFromRedirect || !headquarter?.id}
            options={pointList}
            value={point}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => {
              setPoint(value);
              setRoute('');
            }}
            getOptionLabel={(option) => option?.name || ''}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Select Point/Town" />
            )}
          />

          <Autocomplete
            fullWidth
            disabled={!point?.id}
            autoComplete
            autoHighlight
            autoSelect
            freeSolo
            options={routeList?.map((route) => route.name)}
            value={route}
            onChange={(event, value) => {
              setRoute(value);
            }}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Select Route/Beat" />
            )}
          />

          {isFromRedirect ? (
            <Typography variant="body2" color="text.secondary">
              {`Latitude: ${latitude || 'unknown'}, Longitude: ${
                longitude || 'unknown'
              }`}
            </Typography>
          ) : null}
        </Stack>

        <Stack component={Paper} sx={{ gap: 2, p: 2 }}>
          <Typography variant="subtitle1" color="text.secondary">
            Travel Details
          </Typography>

          {travelEntriesControl}

          <Stack direction="row" sx={{ justifyContent: 'flex-end' }}>
            <Button
              disabled={travelEntries?.length >= 2}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() =>
                setTravelEntries([
                  ...travelEntries,
                  {
                    id: Date.now(),
                    travelStart: '',
                    travelEnd: '',
                    transport: '',
                    travelAllowance: '',
                  },
                ])
              }>
              Add
            </Button>
          </Stack>
        </Stack>

        <Stack component={Paper} sx={{ gap: 2, p: 2 }}>
          <Typography variant="subtitle1" color="text.secondary">
            TA DA & Bills
          </Typography>

          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <Typography>
              Total TA Amount: <b>{totalTA}</b>
            </Typography>

            <FormControlLabel
              disabled={user?.employee?.role?.territoryType?.rank !== 1}
              control={<Checkbox checked={isInTerritory} />}
              label="Inside HQ"
              onChange={() => setIsInTerritory(!isInTerritory)}
            />
          </Stack>

          <TextField
            fullWidth
            disabled
            label="DA Amount"
            type="number"
            value={dearnessAllowance}
            onChange={(e) => {
              const v = roundToDigit(e?.target?.value);
              setDearnessAllowance(v > 0 ? v : '');
            }}
            onWheel={(e) => e.target.blur()}
          />

          <Divider />

          <TextField
            fullWidth
            label="Hotel Bill"
            type="number"
            value={hotelBill}
            onChange={(e) => {
              const v = roundToDigit(e?.target?.value);
              setHotelBill(v > 0 ? v : '');
            }}
            onWheel={(e) => e.target.blur()}
          />

          <TextField
            fullWidth
            label="Hotel Name"
            type="text"
            value={hotelName}
            onChange={(e) => setHotelName(e.target.value)}
          />

          <TextField
            fullWidth
            label="Hotel Contact"
            type="text"
            value={hotelContact}
            onChange={(e) => setHotelContact(e.target.value)}
          />

          <Divider />

          <TextField
            fullWidth
            label="Other Bill"
            type="number"
            value={otherBill}
            onChange={(e) => {
              const v = roundToDigit(e?.target?.value);
              setOtherBill(v > 0 ? v : '');
            }}
            onWheel={(e) => e.target.blur()}
          />

          <TextField
            fullWidth
            label="Other Bill Description"
            type="text"
            multiline
            rows={2}
            value={otherBillDescription}
            onChange={(e) => setOtherBillDescription(e.target.value)}
          />
        </Stack>

        <Button
          disabled={isSubmitting || isDateError}
          variant="contained"
          size="large"
          onClick={handleSubmit}>
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </Button>
      </Stack>
    </Container>
  );
}

export default TaDaForm;
