import React, { useEffect, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import ExpandIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { useLocation, useOutletContext, useNavigate } from 'react-router-dom';
import {
  ExpTable,
  ExpandTableContainer,
} from '../../../assets/style/table/customTable';
import SpecialTable from '../../../component/Table/ExpandTable/SpecialTable';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useExpiredSession from '../../../hooks/useExpiredSession';
import roundToDigit from '../../../utils/roundToDigit';
import { toast } from 'react-toastify';

function ConfirmPayment() {
  const [refetch] = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const data = location?.state?.data;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [payableAmount, setPayableAmount] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(data?.discountAmount);

  const [senderLedgerEntries, setSenderLedgerEntries] = useState([
    { key: 101, type: '', amount: '' },
  ]);
  const [receiverLedgerEntries, setReceiverLedgerEntries] = useState([
    { key: 102, type: '', amount: '' },
  ]);

  const senderLedgerAction = data?.transactionType?.senderLedgerAction;
  const [hasSenderLedger, setHasSenderLedger] = useState(false);
  const [senderLedgerBalance, setSenderLedgerBalance] = useState(0);

  const receiverLedgerAction = data?.transactionType?.receiverLedgerAction;
  const [hasReceiverLedger, setHasReceiverLedger] = useState(false);
  const [receiverLedgerBalance, setReceiverLedgerBalance] = useState(0);

  const [senderTransactions, setSenderTransactions] = useState([]);
  const [receiverTransactions, setReceiverTransactions] = useState([]);

  const [triggerSender, setTriggerSender] = useState(0);
  const [triggerReceiver, setTriggerReceiver] = useState(0);

  useEffect(() => {
    axiosPrivate
      .get(`/api/v1/customer/${data?.sender?.id}?populate=yes`)
      .then((res) => {
        if (res?.data?.data?.customerType?.hasLedger) {
          setHasSenderLedger(true);
          setSenderLedgerBalance(res?.data?.data?.ledgerBalance);
        } else {
          setHasSenderLedger(false);
        }
      })
      .catch((error) => expired(error));
  }, [data?.sender?.id]);

  useEffect(() => {
    axiosPrivate
      .get(`/api/v1/customer/${data?.receiver?.id}?populate=yes`)
      .then((res) => {
        if (res?.data?.data?.customerType?.hasLedger) {
          setHasReceiverLedger(true);
          setReceiverLedgerBalance(res?.data?.data?.ledgerBalance);
        } else {
          setHasReceiverLedger(false);
        }
      })
      .catch((error) => expired(error));
  }, [data?.receiver?.id]);

  useEffect(() => {
    axiosPrivate
      .get(
        `/api/v1/transaction?populate=yes&limit=none&sort=-orderedAt&deliveredAt[exists]=false&or[0][sender]=${data?.sender?.id}&or[1][receiver]=${data?.sender?.id}`,
      )
      .then((res) => {
        setSenderTransactions(res?.data?.data);
      })
      .catch((error) => expired(error));
  }, [data?.sender?.id]);

  useEffect(() => {
    axiosPrivate
      .get(
        `/api/v1/transaction?populate=yes&limit=none&sort=-orderedAt&deliveredAt[exists]=false&or[0][sender]=${data?.receiver?.id}&or[1][receiver]=${data?.receiver?.id}`,
      )
      .then((res) => {
        setReceiverTransactions(res?.data?.data);
      })
      .catch((error) => expired(error));
  }, [data?.receiver?.id]);

  // Get existing sender ledger entries
  React.useEffect(() => {
    if (data?.sender?.id && data?.id) {
      axiosPrivate
        .get(
          `/api/v1/ledger?limit=none&sort=type&customer=${data?.sender?.id}&transactionRef=${data?.id}`,
        )
        .then((res) => {
          const oldEntries = [];
          res?.data?.data?.forEach((e) => {
            oldEntries.push({
              key: e?.id,
              type: e?.type,
              amount: e?.totalAmount,
              date: e?.dateOfDeposit,
              isDisabled: true,
            });
          });

          if (oldEntries?.length > 0) {
            setSenderLedgerEntries(oldEntries);
            setTriggerSender(Date.now());
          }
        })
        .catch((error) => expired(error));
    }
  }, [data?.sender?.id]);

  // Get existing receiver ledger entries
  React.useEffect(() => {
    if (data?.receiver?.id && data?.id) {
      axiosPrivate
        .get(
          `/api/v1/ledger?limit=none&sort=type&customer=${data?.receiver?.id}&transactionRef=${data?.id}`,
        )
        .then((res) => {
          const oldEntries = [];
          res?.data?.data?.forEach((e) => {
            oldEntries.push({
              key: e?.id,
              type: e?.type,
              amount: e?.totalAmount,
              date: e?.dateOfDeposit,
              isDisabled: true,
            });
          });

          if (oldEntries?.length > 0) {
            setReceiverLedgerEntries(oldEntries);
            setTriggerReceiver(Date.now());
          }
        })
        .catch((error) => expired(error));
    }
  }, [data?.receiver?.id]);

  const {
    data: collections,
    isLoading,
    isError,
    error,
  } = useQuery(['transaction-linked-collections', data?.collectionIds], () => {
    let url = '/api/v1/ledger?populate=yes&limit=none&sort=-dateOfDeposit';

    if (data?.collectionRefs?.length > 0) {
      data?.collectionRefs?.forEach((i, index) => {
        url += `&_id[in][${index}]=${i}`;
      });
    } else {
      url += '&type=NOTHING';
    }

    return axiosPrivate.get(url).then((res) => res?.data?.data);
  });

  // Get payable and paid amount
  useEffect(() => {
    const pay = parseFloat(data?.invoiceAmount || data?.payableAmount);
    const paid = parseFloat(data?.paidAmount);

    setPayableAmount(roundToDigit(pay - discountAmount, 5));
    setPaidAmount(roundToDigit(pay - paid - discountAmount, 5));
  }, [
    data?.invoiceAmount,
    data?.payableAmount,
    data?.paidAmount,
    discountAmount,
  ]);

  if (isError) {
    expired(error);
  }

  const handleAddSenderLedgerEntry = () => {
    const entries = [
      ...senderLedgerEntries,
      {
        key: Date.now(),
        type: '',
        amount: '',
      },
    ];

    setSenderLedgerEntries(entries);
    setTriggerSender(Date.now());
  };

  const handleAddReceiverLedgerEntry = () => {
    const entries = [
      ...receiverLedgerEntries,
      {
        key: Date.now(),
        type: '',
        amount: '',
      },
    ];

    setReceiverLedgerEntries(entries);
    setTriggerReceiver(Date.now());
  };

  const handleRemoveSenderLedgerEntry = (key) => {
    const entries = [...senderLedgerEntries];
    const index = entries.findIndex((i) => i.key === key);
    entries.splice(index, 1);

    setSenderLedgerEntries(entries);
    setTriggerSender(Date.now());
  };

  const handleRemoveReceiverLedgerEntry = (key) => {
    const entries = [...receiverLedgerEntries];
    const index = entries.findIndex((i) => i.key === key);
    entries.splice(index, 1);

    setReceiverLedgerEntries(entries);
    setTriggerReceiver(Date.now());
  };

  const handleEditSenderLedgerEntryType = (key, value) => {
    const entries = [...senderLedgerEntries];
    const entry = entries.find((i) => i.key === key);
    entry.type = value;

    setSenderLedgerEntries(entries);
    setTriggerSender(Date.now());
  };

  const handleEditReceiverLedgerEntryType = (key, value) => {
    const entries = [...receiverLedgerEntries];
    const entry = entries.find((i) => i.key === key);
    entry.type = value;

    setReceiverLedgerEntries(entries);
    setTriggerReceiver(Date.now());
  };

  const handleEditSenderLedgerEntryAmount = (key, value) => {
    const entries = [...senderLedgerEntries];
    const entry = entries.find((i) => i.key === key);
    entry.amount = value;

    setSenderLedgerEntries(entries);
    setTriggerSender(Date.now());
  };

  const handleEditReceiverLedgerEntryAmount = (key, value) => {
    const entries = [...receiverLedgerEntries];
    const entry = entries.find((i) => i.key === key);
    entry.amount = value;

    setReceiverLedgerEntries(entries);
    setTriggerReceiver(Date.now());
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    const validSenderEntries = [];
    senderLedgerEntries.forEach((i) => {
      const amount = parseFloat(i.amount);
      if (amount && i.type) {
        validSenderEntries.push({
          type: i.type,
          amount: roundToDigit(amount),
          date: i?.date,
        });
      }
    });

    const validReceiverEntries = [];
    receiverLedgerEntries.forEach((i) => {
      const amount = parseFloat(i.amount);
      if (amount && i.type) {
        validReceiverEntries.push({
          type: i.type,
          amount: roundToDigit(amount),
          date: i?.date,
        });
      }
    });

    axiosPrivate
      .patch(`/api/v1/transaction/${data?.id}/confirm-payment`, {
        paidAmount,
        discountAmount,
        senderLedgerEntries: validSenderEntries,
        receiverLedgerEntries: validReceiverEntries,
      })
      .then((res) => {
        refetch();
        toast.success(res?.data?.message);
        navigate('/transactions');
      })
      .catch((error) => expired(error))
      .finally(() => setIsSubmitting(false));
  };

  const ledgerEntryTypes = [
    { value: '', label: 'None' },
    { value: 'salary', label: 'Salary' },
    { value: 'transport', label: 'Transport' },
    { value: 'damage', label: 'Damage' },
    { value: 'return', label: 'Return' },
    { value: 'bonus', label: 'Bonus' },
    { value: 'commission', label: 'Commission' },
    { value: 'other', label: 'Other funds' },
  ];

  const senderLedgerExtrasControl = useMemo(() => {
    return senderLedgerEntries.map((i) => (
      <Stack key={i.key} direction="row" gap={1} alignItems="center">
        <TextField
          disabled={i?.isDisabled}
          select
          label="Type"
          size="small"
          value={i.type}
          onChange={(e) =>
            handleEditSenderLedgerEntryType(i.key, e.target.value)
          }
          sx={{ minWidth: 136 }}>
          {ledgerEntryTypes.map((i) => (
            <MenuItem key={i.label} dense disabled={!i.value} value={i.value}>
              {i.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          disabled={i?.isDisabled}
          type="number"
          label="Amount"
          size="small"
          fullWidth
          value={i.amount}
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            handleEditSenderLedgerEntryAmount(i.key, e.target.value)
          }
        />

        <Tooltip title="Remove" placement="right">
          <IconButton
            disabled={i?.isDisabled}
            aria-label="remove"
            size="small"
            color="error"
            edge="end"
            onClick={() => handleRemoveSenderLedgerEntry(i.key)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    ));
  }, [triggerSender]);

  const receiverLedgerExtrasControl = useMemo(() => {
    return receiverLedgerEntries.map((i) => (
      <Stack key={i.key} direction="row" gap={1} alignItems="center">
        <TextField
          disabled={i?.isDisabled}
          select
          label="Type"
          size="small"
          value={i.type}
          onChange={(e) =>
            handleEditReceiverLedgerEntryType(i.key, e.target.value)
          }
          sx={{ minWidth: 136 }}>
          {ledgerEntryTypes.map((i) => (
            <MenuItem key={i.label} dense disabled={!i.value} value={i.value}>
              {i.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          disabled={i?.isDisabled}
          type="number"
          label="Amount"
          size="small"
          fullWidth
          value={i.amount}
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            handleEditReceiverLedgerEntryAmount(i.key, e.target.value)
          }
        />

        <Tooltip title="Remove" placement="right">
          <IconButton
            disabled={i?.isDisabled}
            aria-label="remove"
            size="small"
            color="error"
            edge="end"
            onClick={() => handleRemoveReceiverLedgerEntry(i.key)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    ));
  }, [triggerReceiver]);

  const renderTransaction = (transaction) => {
    return (
      <Box key={transaction?.id}>
        <SpecialTable data={transaction} />
      </Box>
    );
  };

  const transactionsView = useMemo(
    () =>
      senderTransactions?.length > 0 || receiverTransactions?.length > 0 ? (
        <Box sx={{ py: 3 }}>
          <Typography
            variant="subtitle1"
            textAlign="center"
            color="primary.main"
            sx={{ mb: 2 }}>
            Pending Transactions
          </Typography>

          {senderTransactions?.length > 0 ? (
            <Accordion variant="outlined">
              <AccordionSummary expandIcon={<ExpandIcon />} sx={containerStyle}>
                {data?.sender?.nameCode} - {data?.sender?.territory?.name}
                <Box component="span" sx={badgeStyle}>
                  {senderTransactions?.length}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Stack gap={2}>
                  {senderTransactions.map((i) => renderTransaction(i))}
                </Stack>
              </AccordionDetails>
            </Accordion>
          ) : null}

          {receiverTransactions?.length > 0 ? (
            <Accordion variant="outlined">
              <AccordionSummary expandIcon={<ExpandIcon />} sx={containerStyle}>
                {data?.receiver?.nameCode} - {data?.receiver?.territory?.name}
                <Box component="span" sx={badgeStyle}>
                  {receiverTransactions?.length}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Stack gap={2}>
                  {receiverTransactions.map((i) => renderTransaction(i))}
                </Stack>
              </AccordionDetails>
            </Accordion>
          ) : null}
        </Box>
      ) : null,
    [receiverTransactions?.length, senderTransactions?.length],
  );

  const collectionsView = useMemo(() => {
    if (isLoading) {
      return (
        <Typography align="center" color="text.disabled" sx={{ py: 3 }}>
          Loading...
        </Typography>
      );
    }

    if (collections?.length < 1) {
      return (
        <Typography align="center" sx={{ py: 3 }}>
          No linked collections
        </Typography>
      );
    }

    const totalCollection = collections?.reduce(
      (accumulator, i) => ({
        totalApproved: accumulator.totalApproved + i?.approvedAmount,
        totalBonus: accumulator.totalBonus + i?.bonusAmount,
        totalCost: accumulator.totalCost + i?.costAmount,
      }),
      { totalApproved: 0, totalBonus: 0, totalCost: 0 },
    );

    return (
      <ExpandTableContainer>
        <ExpTable cellSpacing="0">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Deposit Date</TableCell>
              <TableCell>Depositor</TableCell>
              <TableCell>Post Under</TableCell>
              <TableCell>Approved Amount</TableCell>
              <TableCell>Online Charge</TableCell>
              <TableCell>Incentive</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collections?.map((i) => (
              <TableRow key={i?.id}>
                <TableCell>{i?.shortId}</TableCell>
                <TableCell>
                  {dayjs(i?.dateOfDeposit).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell>{i?.customer?.nameCode}</TableCell>
                <TableCell>{i?.postUnder?.nameCode}</TableCell>
                <TableCell>{i?.approvedAmount}</TableCell>
                <TableCell>{i?.costAmount}</TableCell>
                <TableCell>{i?.bonusAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableRow>
            <TableCell colSpan={4}>
              <b>
                Total:&ensp;
                {totalCollection?.totalApproved +
                  totalCollection?.totalCost +
                  totalCollection?.totalBonus}
              </b>
            </TableCell>
            <TableCell>
              <b>{totalCollection?.totalApproved}</b>
            </TableCell>
            <TableCell>
              <b>{totalCollection?.totalCost}</b>
            </TableCell>
            <TableCell>
              <b>{totalCollection?.totalBonus}</b>
            </TableCell>
          </TableRow>
        </ExpTable>
      </ExpandTableContainer>
    );
  }, [collections, isLoading]);

  const summary = useMemo(() => {
    let newSenderLedgerBalance = senderLedgerBalance;
    let newReceiverLedgerBalance = receiverLedgerBalance;

    const senderActionNotNone = Boolean(senderLedgerAction !== 'none');
    const receiverActionNotNone = Boolean(receiverLedgerAction !== 'none');

    const senderDebit = Boolean(senderLedgerAction === 'debit');
    const receiverDebit = Boolean(receiverLedgerAction === 'debit');

    if (senderActionNotNone) {
      if (senderDebit) {
        newSenderLedgerBalance -= paidAmount;
      } else {
        newSenderLedgerBalance += paidAmount;
      }
    }

    if (receiverActionNotNone) {
      if (receiverDebit) {
        newReceiverLedgerBalance -= paidAmount;
      } else {
        newReceiverLedgerBalance += paidAmount;
      }
    }

    const validSenderEntries = [];
    senderLedgerEntries.forEach((i) => {
      const amount = parseFloat(i.amount);
      if (amount && i.type) {
        validSenderEntries.push({
          ...i,
          actual: roundToDigit(amount),
          isDebit: Boolean(amount < 0),
        });
        newSenderLedgerBalance += amount;
      }
    });

    const validReceiverEntries = [];
    receiverLedgerEntries.forEach((i) => {
      const amount = parseFloat(i.amount);
      if (amount && i.type) {
        validReceiverEntries.push({
          ...i,
          actual: roundToDigit(amount),
          isDebit: Boolean(amount < 0),
        });
        newReceiverLedgerBalance += amount;
      }
    });

    newSenderLedgerBalance = roundToDigit(newSenderLedgerBalance);
    newReceiverLedgerBalance = roundToDigit(newReceiverLedgerBalance);

    return (
      <Grid container spacing={2} sx={{ py: 3 }}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            textAlign="center"
            color="primary.main">
            Ledger Update Summary
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Stack
            direction="column"
            component={Paper}
            variant="outlined"
            sx={{ gap: 0.5, p: 2 }}>
            <Typography variant="caption" fontWeight="bold" textAlign="center">
              Sender's ledger
            </Typography>
            <Typography variant="subtitle2" textAlign="center">
              {data?.sender?.nameCode} - {data?.sender?.territory?.name}
            </Typography>

            {Boolean(
              (hasSenderLedger && senderActionNotNone) ||
                validSenderEntries.length > 0,
            ) ? (
              <>
                <Stack direction="row" gap={3} justifyContent="space-between">
                  <Typography variant="body2">Current Balance</Typography>
                  <Typography variant="body2" fontWeight="bold">
                    {senderLedgerBalance}
                  </Typography>
                </Stack>

                {validSenderEntries.map((i) => (
                  <Stack
                    key={i.key}
                    direction="row"
                    gap={3}
                    justifyContent="space-between">
                    <Typography variant="body2" textTransform="capitalize">
                      {i.type}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      color={i.isDebit ? 'error.main' : 'success.main'}>
                      {i.isDebit ? '' : '+'}
                      {i.actual}
                    </Typography>
                  </Stack>
                ))}

                {senderActionNotNone ? (
                  <Stack direction="row" gap={3} justifyContent="space-between">
                    <Typography variant="body2">Payment</Typography>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      color={senderDebit ? 'error.main' : 'success.main'}>
                      {senderDebit ? '-' : '+'}
                      {paidAmount}
                    </Typography>
                  </Stack>
                ) : null}

                <Divider />

                <Stack direction="row" gap={3} justifyContent="space-between">
                  <Typography variant="body2">Updated Balance</Typography>
                  <Typography variant="body2" fontWeight="bold">
                    {newSenderLedgerBalance}
                  </Typography>
                </Stack>
              </>
            ) : (
              <Typography textAlign="center" color="text.disabled">
                NO UPDATE
              </Typography>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12} md={6}>
          <Stack
            direction="column"
            component={Paper}
            variant="outlined"
            sx={{ gap: 0.5, p: 2 }}>
            <Typography variant="caption" fontWeight="bold" textAlign="center">
              Receiver's ledger
            </Typography>
            <Typography variant="subtitle2" textAlign="center">
              {data?.receiver?.nameCode} - {data?.receiver?.territory?.name}
            </Typography>

            {Boolean(
              (hasReceiverLedger && receiverActionNotNone) ||
                validReceiverEntries.length > 0,
            ) ? (
              <>
                <Stack direction="row" gap={3} justifyContent="space-between">
                  <Typography variant="body2">Current Balance</Typography>
                  <Typography variant="body2" fontWeight="bold">
                    {receiverLedgerBalance}
                  </Typography>
                </Stack>

                {validReceiverEntries.map((i) => (
                  <Stack
                    key={i.key}
                    direction="row"
                    gap={3}
                    justifyContent="space-between">
                    <Typography variant="body2" textTransform="capitalize">
                      {i.type}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      color={i.isDebit ? 'error.main' : 'success.main'}>
                      {i.isDebit ? '' : '+'}
                      {i.actual}
                    </Typography>
                  </Stack>
                ))}

                {receiverActionNotNone ? (
                  <Stack direction="row" gap={3} justifyContent="space-between">
                    <Typography variant="body2">Payment</Typography>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      color={receiverDebit ? 'error.main' : 'success.main'}>
                      {receiverDebit ? '-' : '+'}
                      {paidAmount}
                    </Typography>
                  </Stack>
                ) : null}

                <Divider />

                <Stack direction="row" gap={3} justifyContent="space-between">
                  <Typography variant="body2">Updated Balance</Typography>
                  <Typography variant="body2" fontWeight="bold">
                    {newReceiverLedgerBalance}
                  </Typography>
                </Stack>
              </>
            ) : (
              <Typography textAlign="center" color="text.disabled">
                NO UPDATE
              </Typography>
            )}
          </Stack>
        </Grid>
      </Grid>
    );
  }, [
    paidAmount,
    senderLedgerBalance,
    receiverLedgerBalance,
    triggerSender,
    triggerReceiver,
  ]);

  return (
    <Box component={Paper} sx={{ maxWidth: 1200, mx: 'auto', p: 3 }}>
      <Typography variant="h6" textAlign="center" color="primary.main">
        Confirm Payment
      </Typography>

      <Stack
        direction="column"
        sx={{ gap: 0.5, maxWidth: 400, mx: 'auto', pt: 3 }}>
        <Stack direction="row" gap={3} justifyContent="space-between">
          <Typography>Payable Amount</Typography>
          <Typography fontWeight="bold">{payableAmount}</Typography>
        </Stack>

        {data?.paidAmount !== 0 ? (
          <Stack direction="row" gap={3} justifyContent="space-between">
            <Typography>Already Paid</Typography>
            <Typography fontWeight="bold">{data?.paidAmount}</Typography>
          </Stack>
        ) : null}

        <br />

        <TextField
          type="number"
          label="Discount Amount"
          fullWidth
          value={discountAmount}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => setDiscountAmount(e.target.value)}
        />

        <br />

        <TextField
          type="number"
          label="Add Payment"
          fullWidth
          autoFocus
          value={paidAmount}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => setPaidAmount(e.target.value)}
        />
      </Stack>

      <Box sx={{ py: 3 }}>
        <Typography
          variant="subtitle1"
          textAlign="center"
          color="primary.main"
          sx={{ mb: 2 }}>
          Linked Collections
        </Typography>
        <Paper variant="outlined" sx={{ overflow: 'hidden' }}>
          {collectionsView}
        </Paper>
      </Box>

      {transactionsView}

      {hasSenderLedger || hasReceiverLedger ? (
        <Grid container spacing={2} sx={{ py: 3, justifyContent: 'center' }}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              textAlign="center"
              color="primary.main">
              Ledger Adjustments
            </Typography>
          </Grid>

          {hasSenderLedger ? (
            <Grid item xs={12} md={6}>
              <Stack
                direction="column"
                component={Paper}
                variant="outlined"
                sx={{ gap: 3, p: 2, alignItems: 'center' }}>
                <Typography variant="subtitle2" textAlign="center">
                  {data?.sender?.nameCode} - {data?.sender?.territory?.name}
                </Typography>

                {senderLedgerExtrasControl}

                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<AddIcon />}
                  sx={{ maxWidth: 96 }}
                  onClick={handleAddSenderLedgerEntry}>
                  Add
                </Button>
              </Stack>
            </Grid>
          ) : null}

          {hasReceiverLedger ? (
            <Grid item xs={12} md={6}>
              <Stack
                direction="column"
                component={Paper}
                variant="outlined"
                sx={{ gap: 3, p: 2, alignItems: 'center' }}>
                <Typography variant="subtitle2" textAlign="center">
                  {data?.receiver?.nameCode} - {data?.receiver?.territory?.name}
                </Typography>

                {receiverLedgerExtrasControl}

                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<AddIcon />}
                  sx={{ maxWidth: 96 }}
                  onClick={handleAddReceiverLedgerEntry}>
                  Add
                </Button>
              </Stack>
            </Grid>
          ) : null}
        </Grid>
      ) : null}

      <br />
      <hr />

      {summary}

      <Box textAlign="center">
        <Button
          disabled={isSubmitting}
          color="primary"
          variant="contained"
          size="large"
          onClick={handleSubmit}>
          Confirm
        </Button>
      </Box>
    </Box>
  );
}

export default ConfirmPayment;

const containerStyle = {
  '.MuiAccordionSummary-content': {
    gap: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};

const badgeStyle = (theme) => ({
  px: 0.75,
  py: 0.25,
  mr: 1,
  borderRadius: 1,
  fontSize: '0.875rem',
  color: theme?.palette?.error?.contrastText,
  backgroundColor: theme?.palette?.error?.main,
  boxShadow: theme?.shadows?.[2],
});
