import React, { useEffect, useMemo, useState } from 'react';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmModal from '../../component/ConfirmModal';
import {
  DateRangeColumnFilter,
  MultiSelectColumnFilter,
} from '../../component/Table/SelectColumnFilter';
import Table from '../../component/Table/Table';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import useHeader from '../../hooks/useHeader';
import { offerTypeLabels } from '../../staticData/ProductUnits';
import roundToDigit from '../../utils/roundToDigit';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// import ProductExpandRow from '../Product/AllProducts/ProductExpandRow';

function MarketPrice() {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const { setHeader } = useHeader();
  const { role, userId, Admins } = useAuth();

  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  useEffect(() => {
    setHeader({ title: 'Market Price' });
  }, []);

  const { data, isLoading, isError, error, refetch } = useQuery(
    'market-price',
    () =>
      axiosPrivate
        .get('/api/v1/market-price?populate=yes&limit=none&sort=-dateOfEntry')
        .then((res) =>
          res?.data?.data?.map((i) => {
            const mp = { ...i };

            const qpc = parseFloat(i?.product?.quantityPerCarton);
            const free = parseFloat(i?.freeItemsPerCarton);
            const moq = parseFloat(i?.minimumOrderQuantityForOffer);
            const totalQpc = qpc + free;

            const depotPrice = parseFloat(i?.depotPrice);
            const dsrPrice = parseFloat(i?.dsrPrice);
            const dealerPrice = parseFloat(i?.dealerPrice);
            const tradePrice = parseFloat(i?.tradePrice);
            const maxRetailPrice = parseFloat(i?.maxRetailPrice);

            const depotPriceCtn = depotPrice * qpc;
            const dsrPriceCtn = dsrPrice * qpc;
            const dealerPriceCtn = dealerPrice * qpc;
            const tradePriceCtn = tradePrice * qpc;
            const maxRetailPriceCtn = maxRetailPrice * qpc;

            const esDepotPrice = depotPriceCtn / totalQpc;
            const esDsrPrice = dsrPriceCtn / totalQpc;
            const esDealerPrice = dealerPriceCtn / totalQpc;
            const esTradePrice = tradePriceCtn / totalQpc;

            const esDealerPriceCtn = esDealerPrice * qpc;
            const esTradePriceCtn = esTradePrice * qpc;

            mp.viewDepotPrice = roundToDigit(depotPriceCtn);
            mp.viewDsrPrice = roundToDigit(dsrPriceCtn);
            mp.viewDealerPrice = roundToDigit(dealerPriceCtn);
            mp.viewTradePrice = roundToDigit(tradePriceCtn);
            mp.viewMaxRetailPrice = roundToDigit(maxRetailPriceCtn);

            mp.viewEsDealerPrice = roundToDigit(esDealerPriceCtn);
            mp.viewEsTradePrice = roundToDigit(esTradePriceCtn);

            mp.viewEsDealerPricePkt = roundToDigit(esDealerPriceCtn / totalQpc);
            mp.viewEsTradePricePkt = roundToDigit(esTradePriceCtn / totalQpc);

            mp.viewRoIDepot = esDepotPrice
              ? `${roundToDigit(
                  ((esDealerPrice - esDepotPrice) / esDepotPrice) * 100,
                )}%`
              : '-';
            mp.viewRoIDsr = esDsrPrice
              ? `${roundToDigit(
                  ((esTradePrice - esDsrPrice) / esDsrPrice) * 100,
                )}%`
              : '-';
            mp.viewRoIDealer = esDealerPrice
              ? `${roundToDigit(
                  ((esTradePrice - esDealerPrice) / esDealerPrice) * 100,
                )}%`
              : '-';
            mp.viewRoIRetailer = esTradePrice
              ? `${roundToDigit(
                  ((maxRetailPrice - esTradePrice) / esTradePrice) * 100,
                )}%`
              : '-';

            mp.viewTotalQuantityPerCarton = roundToDigit(totalQpc);

            mp.viewFreeRatio = roundToDigit(free / qpc, 5);

            mp.viewGiftItem =
              i?.giftItemDescription && i?.giftItemDescription !== 'none'
                ? `${i?.giftItemDescription}`
                : '-';

            mp.viewMOQ = moq ? `${roundToDigit(moq)} ctn` : '-';

            mp.viewOfferType = offerTypeLabels[i?.offerType];

            mp.viewUnitPerPacket = `${i?.product?.unitPerPacket} ${i?.product?.unitOfMeasurement}`;

            mp.viewMonth = dayjs(i?.dateOfPrice).format('MMM-YYYY');

            return mp;
          }),
        ),
  );

  if (isError) {
    expired(error);
  }

  const handleDelete = () => {
    axiosPrivate
      .delete(`/api/v1/market-price/${deleteId}`)
      .then((res) => {
        if (res.status === 204) {
          toast.success('Document was deleted');
          refetch();
        }
      })
      .catch((error) => expired(error))
      .finally(() => setDeleteShow(false));
  };

  const handleAdd = () => {
    navigate('/market-price/add');
  };

  const handleEdit = (id) => {
    navigate(`/market-price/edit/${id}`);
  };

  const COLUMNS = useMemo(() => {
    const col = [
      /* {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => (
          <>
            {row.isExpanded ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </>
        ),
      }, */
      {
        Header: 'Company',
        accessor: 'product.productCompany.productCompany',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Entry Date',
        accessor: 'dateOfEntry',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY');
          return value;
        },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Price List Date',
        accessor: 'dateOfPrice',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY');
          return value;
        },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Month',
        accessor: 'viewMonth',
      },
      {
        Header: 'Name & Code',
        accessor: 'product.nameCode',
      },
      {
        Header: 'Category',
        accessor: 'product.productCategory.productCategory',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Unit / PKT',
        accessor: 'viewUnitPerPacket',
      },
      {
        Header: 'Net WT / PKT',
        accessor: 'product.weightPerPacket',
      },
      {
        Header: 'PKT / CTN',
        accessor: 'product.quantityPerCarton',
      },
      {
        Header: 'Offer Type',
        accessor: 'viewOfferType',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Gift Item',
        accessor: 'viewGiftItem',
      },
      {
        Header: 'Free / CTN',
        accessor: 'freeItemsPerCarton',
      },
      {
        Header: 'Total PKT / CTN',
        accessor: 'viewTotalQuantityPerCarton',
      },
      {
        Header: 'Free Ratio / CTN',
        accessor: 'viewFreeRatio',
      },
      {
        Header: 'MOQ for Offer',
        accessor: 'viewMOQ',
      },
      {
        Header: 'Depot Price / CTN',
        accessor: 'viewDepotPrice',
      },
      {
        Header: 'DSR Price / CTN',
        accessor: 'viewDsrPrice',
      },
      {
        Header: 'Dealer Price / CTN',
        accessor: 'viewDealerPrice',
      },
      {
        Header: 'Trade Price / CTN',
        accessor: 'viewTradePrice',
      },
      {
        Header: 'MRP / CTN',
        accessor: 'viewMaxRetailPrice',
      },
      {
        Header: 'EDP / CTN',
        accessor: 'viewEsDealerPrice',
      },
      {
        Header: 'ETP / CTN',
        accessor: 'viewEsTradePrice',
      },
      {
        Header: 'EDP / PKT',
        accessor: 'viewEsDealerPricePkt',
      },
      {
        Header: 'ETP / PKT',
        accessor: 'viewEsTradePricePkt',
      },
      {
        Header: 'MRP / PKT',
        accessor: 'maxRetailPrice',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'ROI Depot',
        accessor: 'viewRoIDepot',
      },
      {
        Header: 'ROI DSR',
        accessor: 'viewRoIDsr',
      },
      {
        Header: 'ROI Dealer',
        accessor: 'viewRoIDealer',
      },
      {
        Header: 'ROI Retailer',
        accessor: 'viewRoIRetailer',
      },
      {
        Header: 'Action',
        id: 'Action',
        Cell: ({ row }) => {
          if (
            (role !== 'Admin' && ['Super Admin', ...Admins].includes(role)) ||
            row?.original?.recordedBy?.id === userId
          ) {
            return (
              <Stack direction="row" alignItems="center" spacing={2}>
                <IconButton
                  onClick={() => {
                    handleEdit(row?.original?.id);
                  }}
                  className="Table_icon_btn">
                  <BorderColorIcon />
                </IconButton>

                <IconButton
                  onClick={() => {
                    setDeleteId(row?.original?.id);
                    setDeleteShow(true);
                  }}
                  className="Table_icon_btn delete">
                  <DeleteIcon />
                </IconButton>
              </Stack>
            );
          }
          return null;
        },
      },
    ];

    return col;
  }, []);

  if (location.pathname !== '/market-price') {
    return <Outlet context={[refetch]} />;
  }

  return (
    <>
      <Table
        columns={COLUMNS}
        data={data || []}
        isLoading={isLoading}
        filename="Market-price"
        addButtonTooltip="Add Market Price"
        onAddButtonClick={handleAdd}
        // expandRow={ProductExpandRow}
        // expandRowProps={{ isMarketPrice: true }}
        // hasDrawer={true}
        fullHeight={true}
        noWrap={true}
      />

      <ConfirmModal
        title="Are you sure?"
        subtitle="You won't be able to revert this!"
        show={deleteShow}
        onSave={() => handleDelete()}
        onHide={() => setDeleteShow(false)}
        actionButtonLabel="Delete"
        actionButtonColor="error"
      />
    </>
  );
}

export default MarketPrice;
