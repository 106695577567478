import React, { useEffect, useMemo } from 'react';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { useQuery } from 'react-query';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmModal from '../../../component/ConfirmModal';
import {
  MultiSelectColumnFilter,
  SelectColumnFilterBool,
} from '../../../component/Table/SelectColumnFilter';
import Table from '../../../component/Table/Table';
import useAuth from '../../../hooks/useAuth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useExpiredSession from '../../../hooks/useExpiredSession';
import useHeader from '../../../hooks/useHeader';
import roundToDigit from '../../../utils/roundToDigit';
import ProductExpandRow from './ProductExpandRow';

function ViewProduct() {
  const location = useLocation();
  const { setHeader } = useHeader();
  const { role, Admins } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();

  useEffect(() => {
    setHeader({ title: 'Products' });
  }, []);

  // get All Product data
  const { data, isLoading, isFetching, isError, error, refetch } = useQuery(
    'AllProduct',
    () => {
      let url = '/api/v1/product?populate=yes&limit=none&sort=-createdAt';

      if (!['Super Admin', ...Admins].includes(role)) {
        url += '&isOwn=true';
      }

      return axiosPrivate.get(url).then((res) => {
        return res?.data?.data?.map((i) => {
          const p = { ...i };
          const qpc = parseFloat(i?.quantityPerCarton);

          p.viewDealerPrice = roundToDigit(parseFloat(i?.dealerPrice) * qpc);
          p.viewDsrPrice = roundToDigit(parseFloat(i?.dsrPrice) * qpc);
          p.viewTradePrice = roundToDigit(parseFloat(i?.tradePrice) * qpc);

          return p;
        });
      });
    },
  );

  const [deleteShow, setDeleteShow] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState('');
  const navigate = useNavigate();

  const handleDelete = () => {
    axiosPrivate
      .delete(`/api/v1/product/${deleteId}`)
      .then((res) => {
        if (res.status === 204) {
          toast.success('Document was deleted');
          refetch();
        }
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      })
      .finally(() => {
        setDeleteShow(false);
      });
  };

  const handleAdd = () => {
    navigate('/products/add', {
      state: { title: 'Add Product' },
    });
  };

  const handleEdit = (id) => {
    navigate(`/products/edit/${id}`, {
      state: { title: 'Edit Product' },
    });
  };

  const COLUMNS = useMemo(() => {
    const col = [
      {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => (
          <>
            {row.isExpanded ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </>
        ),
      },
    ];

    if (['Super Admin', ...Admins].includes(role)) {
      col.push({
        Header: 'Company',
        accessor: 'productCompany.productCompany',
        Cell: ({ value, row }) => (
          <Stack direction="row" sx={{ alignItems: 'center' }}>
            {value}
            &ensp;
            {row?.original?.isOwn ? (
              <Tooltip title="Own company" placement="bottom-end" arrow>
                <FileDownloadDoneIcon fontSize="small" color="success" />
              </Tooltip>
            ) : null}
          </Stack>
        ),
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      });
    }

    col.push(
      {
        Header: 'Category',
        accessor: 'productCategory.productCategory',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Name & Code',
        accessor: 'nameCode',
      },
      {
        Header: 'Carton',
        accessor: 'quantityPerCarton',
      },
      {
        Header: 'DSRP / CTN',
        accessor: 'viewDsrPrice',
      },
      {
        Header: 'DP / CTN',
        accessor: 'viewDealerPrice',
      },
      {
        Header: 'TP / CTN',
        accessor: 'viewTradePrice',
      },
      {
        Header: 'MRP / PKT',
        accessor: 'maxRetailPrice',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Trade Offer',
        accessor: 'offerType',
        Cell: ({ value, row }) => {
          switch (value) {
            case 'free':
              return `${row?.original?.freeItemsPerCarton} PCS / CTN`;

            case 'gift':
              return `${row?.original?.giftItemDescription}`;

            default:
              return '';
          }
        },
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Active',
        accessor: 'isActive',
        Cell: ({ value, row }) => {
          if (value) {
            return <CheckCircleIcon color="success" />;
          }
          return <HighlightOffIcon color="error" />;
        },
        Filter: SelectColumnFilterBool,
      },
      {
        Header: 'Action',
        id: 'Action',
        Cell: ({ row }) => (
          <Stack direction="row" alignItems="center" spacing={2}>
            <IconButton
              onClick={() => {
                handleEdit(row?.original?.id);
              }}
              className="Table_icon_btn">
              <BorderColorIcon />
            </IconButton>
            <Box>
              <IconButton
                onClick={() => {
                  setDeleteId(row?.original?.id);
                  setDeleteShow(true);
                }}
                className="Table_icon_btn delete">
                <DeleteIcon />
              </IconButton>
            </Box>
          </Stack>
        ),
      },
    );

    return col;
  }, [role]);

  if (isError) {
    expired(error);
  }

  if (location.pathname !== '/products') {
    return <Outlet context={[refetch]} />;
  }

  return (
    <>
      <Table
        columns={COLUMNS}
        data={data || []}
        isLoading={isLoading || isFetching}
        filename="Products"
        addButtonTooltip="Add Product"
        onAddButtonClick={handleAdd}
        expandRow={ProductExpandRow}
        fullHeight={true}
      />

      <ConfirmModal
        title="Are you sure?"
        subtitle="You won't be able to revert this!"
        show={deleteShow}
        onSave={() => handleDelete()}
        onHide={() => setDeleteShow(false)}
        actionButtonLabel="Delete"
        actionButtonColor="error"
      />
    </>
  );
}

export default ViewProduct;
