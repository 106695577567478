import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../component/Button';
import HeadingTitle from '../../component/HeadingTitle';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { BankList, MobileBankList } from '../../staticData/Banks';
import roundToDigit from '../../utils/roundToDigit';

export default function ModifiedCollections() {
  const { role, userId, Admins, SalesManagers, SalesOfficers } = useAuth();
  const navigate = useNavigate();
  const [refetch] = useOutletContext();
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const id = params?.collectionID;

  const [isLoading, setIsLoading] = useState(false);
  const [isDateError1, setIsDateError1] = useState(false);
  const [isDateError2, setIsDateError2] = useState(false);
  const [isDateError3, setIsDateError3] = useState(false);

  const [customersList, setCustomersList] = useState([]);
  const [customer, setCustomer] = useState({});
  const [postUnder, setPostUnder] = useState({});
  const [entryAmount, setEntryAmount] = useState('');
  const [approvedAmount, setApprovedAmount] = useState('');
  const [bonusAmount, setBonusAmount] = useState('');
  const [costAmount, setCostAmount] = useState('');
  const [dateOfEntry, setDateOfEntry] = useState(new Date());
  const [dateOfDeposit, setDateOfDeposit] = useState(null);
  const [dateOfClearance, setDateOfClearance] = useState(null);
  const [paymentMode, setPaymentMode] = useState('Bank');
  const [bankName, setBankName] = useState('');
  const [bankBranch, setBankBranch] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [transactionID, setTransactionID] = useState('');
  const [reference, setReference] = useState('');
  const [remarks, setRemarks] = useState('');

  // get edit collection data by id
  useEffect(() => {
    if (id) {
      axiosPrivate
        .get(`/api/v1/ledger/${id}?populate=yes`)
        .then((res) => {
          const data = res?.data?.data;

          setCustomer(data?.customer);
          setPostUnder(data?.postUnder);
          setEntryAmount(data?.entryAmount);
          setApprovedAmount(data?.approvedAmount);
          setBonusAmount(data?.bonusAmount);
          setCostAmount(data?.costAmount);
          setDateOfEntry(data?.dateOfEntry);
          setDateOfDeposit(data?.dateOfDeposit);
          setDateOfClearance(data?.dateOfClearance);
          setPaymentMode(data?.paymentMode);
          setBankName(data?.bankName);
          setBankBranch(data?.bankBranch);
          setAccountNumber(data?.accountNumber);
          setTransactionID(data?.transactionID);
          setReference(data?.reference);
          setRemarks(data?.remarks);
        })
        .catch((error) => console.warn(error));
    } else {
      setCustomer({});
      setPostUnder({});
      setEntryAmount();
      setApprovedAmount(null);
      setBonusAmount(null);
      setCostAmount(null);
      setDateOfEntry(new Date());
      setDateOfDeposit(null);
      setDateOfClearance(null);
      setPaymentMode('Bank');
      setBankName('');
      setBankBranch('');
      setAccountNumber(null);
      setTransactionID(null);
      setReference('');
      setRemarks('');
    }
  }, [id]);

  // get customer data
  useEffect(() => {
    axiosPrivate
      .get('/api/v1/customer?populate=yes&limit=none&isActive=true')
      .then((res) => {
        setCustomersList(res?.data?.data);
      })
      .catch((error) => toast.warn(error?.response?.data?.message));
  }, []);

  const handleSubmit = () => {
    setIsLoading(true);
    axiosPrivate({
      method: id ? 'patch' : 'post',
      url: id ? `/api/v1/ledger/${id}` : '/api/v1/ledger',
      data: {
        customer: customer?.id,
        postUnder: postUnder?.id || null,
        recordedBy: userId,
        entryAmount: entryAmount > 0 ? entryAmount : 0,
        approvedAmount: approvedAmount > 0 ? approvedAmount : 0,
        bonusAmount: bonusAmount > 0 ? bonusAmount : 0,
        costAmount: costAmount > 0 ? costAmount : 0,
        dateOfEntry,
        dateOfDeposit,
        dateOfClearance,
        paymentMode,
        bankName,
        bankBranch,
        accountNumber,
        transactionID,
        reference,
        remarks,
      },
    })
      .then((res) => {
        toast.success(res?.data?.message);
        refetch();
        navigate('/collections');
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      })
      .finally(() => setIsLoading(false));
  };

  const lowerRole = [
    'DO',
    'IMS',
    'Factory',
    ...SalesManagers,
    ...SalesOfficers,
    'SD',
    'Default',
  ];

  return (
    <>
      <HeadingTitle>{id ? 'Edit' : 'Add'} Collection</HeadingTitle>
      <Box
        mt={2}
        sx={{
          display: 'grid',
          gridTemplateColumns: [...SalesManagers, 'SD'].includes(role)
            ? '1fr'
            : { lg: '6fr 3fr 3fr', md: '1fr' },
          gap: '10px',
        }}
        mb={2}>
        <Paper sx={{ p: 1 }}>
          <Typography variant="caption">ASM Section</Typography>

          <br />
          <br />

          <Grid container spacing={2} fullWidth>
            <Grid item xs={12}>
              <Autocomplete
                size="small"
                fullWidth
                disabled={
                  (id && lowerRole.includes(role)) || customersList.length < 1
                }
                options={customersList}
                value={customer}
                isOptionEqualToValue={(option, value) =>
                  value?.id === option?.id
                }
                onChange={(event, value) => setCustomer(value)}
                getOptionLabel={(option) =>
                  option?.name
                    ? `${option?.name} (${option?.code}) ${
                        id ? '' : `- ${option?.territory?.name}`
                      }`
                    : ''
                }
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Depositor" />
                )}
              />
              <Box sx={{ px: 1.5, pt: 0.5 }}>
                <Typography variant="caption">Location</Typography>
                <Typography>{customer?.territory?.code}</Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                // disabled={role === 'Accounts'}
                size="small"
                type="number"
                label="Collection Entry Amount"
                fullWidth
                onWheel={(e) => e.target.blur()}
                value={entryAmount}
                onChange={(e) => setEntryAmount(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth size="small">
                <InputLabel>Payment Mode</InputLabel>
                <Select
                  // disabled={role === 'Accounts'}
                  label="Payment Mode"
                  defaultValue="Bank"
                  value={paymentMode}
                  onChange={(e) => {
                    setPaymentMode(e.target.value);
                    setBankName(null);
                  }}>
                  <MenuItem value="Bank">Bank</MenuItem>
                  <MenuItem value="Mobile Bank">Mobile Bank</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {paymentMode === 'Bank' ? (
              <>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    // disabled={role === 'Accounts'}
                    size="small"
                    fullWidth
                    options={BankList}
                    value={bankName}
                    onChange={(event, value) => setBankName(value)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth label="Bank Name" />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    // disabled={role === 'Accounts'}
                    size="small"
                    type="text"
                    label="Account Number"
                    fullWidth
                    value={accountNumber}
                    onChange={(e) => setAccountNumber(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    // disabled={role === 'Accounts'}
                    size="small"
                    type="text"
                    label="Bank Branch"
                    fullWidth
                    value={bankBranch}
                    onChange={(e) => setBankBranch(e.target.value)}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    // disabled={role === 'Accounts'}
                    size="small"
                    fullWidth
                    options={MobileBankList}
                    value={bankName}
                    onChange={(event, value) => setBankName(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Mobile Bank Name"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    // disabled={role === 'Accounts'}
                    size="small"
                    type="text"
                    label="Mobile Number"
                    fullWidth
                    value={accountNumber}
                    onChange={(e) => setAccountNumber(e.target.value)}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} md={6}>
              <TextField
                // disabled={role === 'Accounts'}
                size="small"
                type="text"
                label="Reference"
                fullWidth
                value={reference}
                onChange={(e) => setReference(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  disabled={
                    ![
                      'Super Admin',
                      ...Admins,
                      'Director Operations',
                      'Director Finance',
                      'Managing Director',
                      'Accounts',
                    ].includes(role)
                  }
                  label="Entry Date"
                  disableFuture
                  inputFormat="DD/MM/YYYY"
                  value={dateOfEntry}
                  onError={(reason) => setIsDateError1(Boolean(reason))}
                  onChange={(newValue) => setDateOfEntry(newValue)}
                  renderInput={(params) => (
                    <TextField size="small" {...params} fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  // disabled={role === 'Accounts'}
                  label="Deposit Date"
                  disableFuture
                  inputFormat="DD/MM/YYYY"
                  value={dateOfDeposit}
                  onError={(reason) => setIsDateError2(Boolean(reason))}
                  onChange={(newValue) => setDateOfDeposit(newValue)}
                  renderInput={(params) => (
                    <TextField size="small" {...params} fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Paper>

        {[...SalesManagers, 'SD'].includes(role) ? null : (
          <>
            <Paper
              sx={{
                p: 1,
                borderTop: role === 'Accounts' ? '4px solid blue' : '',
              }}>
              <Typography variant="caption">Account Section</Typography>

              <br />
              <br />

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    type="number"
                    label="Approved Collection"
                    fullWidth
                    onWheel={(e) => e.target.blur()}
                    value={approvedAmount}
                    onChange={(e) => {
                      setApprovedAmount(e.target.value);
                      setCostAmount(entryAmount - e.target.value);
                      if (id && role === 'Accounts' && !dateOfClearance) {
                        setDateOfClearance(new Date());
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    size="small"
                    type="number"
                    label="Online Charge"
                    fullWidth
                    onWheel={(e) => e.target.blur()}
                    value={costAmount}
                    onChange={(e) => setCostAmount(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    size="small"
                    type="text"
                    label="Transaction Code"
                    fullWidth
                    value={transactionID}
                    onChange={(e) => setTransactionID(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Clearance Date"
                      disableFuture
                      inputFormat="DD/MM/YYYY"
                      value={dateOfClearance}
                      onError={(reason) => setIsDateError3(Boolean(reason))}
                      onChange={(newValue) => setDateOfClearance(newValue)}
                      renderInput={(params) => (
                        <TextField size="small" {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Paper>

            <Paper
              sx={{ p: 1, borderTop: role === 'DO' ? '4px solid blue' : '' }}>
              <Typography variant="caption">DO Section</Typography>

              <br />
              <br />

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    size="small"
                    fullWidth
                    // disabled={customersList.length < 1 || role === 'Accounts'}
                    disabled={customersList.length < 1}
                    options={customersList}
                    value={postUnder}
                    isOptionEqualToValue={(option, value) =>
                      value?.id === option?.id
                    }
                    onChange={(event, value) => setPostUnder(value)}
                    getOptionLabel={(option) =>
                      option?.name
                        ? `${option?.name} (${option?.code}) - ${option?.territory?.name}`
                        : ''
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth label="Post Under" />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    // disabled={role === 'Accounts'}
                    size="small"
                    type="number"
                    label="Incentive Amount"
                    fullWidth
                    onWheel={(e) => e.target.blur()}
                    value={bonusAmount}
                    onChange={(e) => setBonusAmount(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Auto set incentive amount">
                            <IconButton
                              aria-label="Auto set incentive amount"
                              color="primary"
                              edge="end"
                              onClick={() => {
                                setBonusAmount(
                                  roundToDigit(
                                    (parseFloat(approvedAmount) +
                                      parseFloat(costAmount)) /
                                      100,
                                  ),
                                );
                              }}>
                              <MonetizationOnIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    // disabled={role === 'Accounts'}
                    size="small"
                    type="text"
                    label="Remarks"
                    multiline
                    rows={3}
                    fullWidth
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Paper>
          </>
        )}
      </Box>
      <Stack direction="row" justifyContent="end" pt={0.5}>
        <Button
          disabled={isLoading || isDateError1 || isDateError2 || isDateError3}
          type="submit"
          text="Save"
          onClick={handleSubmit}
        />
      </Stack>
    </>
  );
}
