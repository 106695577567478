import { Box, Table, Typography, styled } from '@mui/material';

export const ExpandTableCaption = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  display: 'block',
  color: '#fff',
  background: theme.palette.primary.main,
  padding: '5px',
}));

export const ExpandTableContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  overflowX: 'auto',
}));

export const ExpTable = styled(Table)(({ theme }) => ({
  th: {
    fontWeight: 900,
    fontFamily: 'Inter',
    padding: 5,
    width: 'max-content',
    // whiteSpace: 'nowrap',
    fontSize: 13,
  },
  td: {
    padding: 5,
    fontSize: 13,
    // whiteSpace: 'nowrap',
  },
}));
