import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Link, Outlet, useLocation } from 'react-router-dom';

export default function LiftingPlan() {
  const location = useLocation();
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    if (location?.pathname === '/lifting-plan-details') {
      setValue(0);
    }
    if (location?.pathname === '/lifting-plan-summary') {
      setValue(1);
    }
  }, [location?.pathname]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value}>
          <Tab label="Details" component={Link} to={'/lifting-plan-details'} />
          <Tab label="Summary" component={Link} to={'/lifting-plan-summary'} />
        </Tabs>
      </Box>
      <Box pt={3}>
        <Outlet />
      </Box>
    </Box>
  );
}
