import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import RemoveIcon from '@mui/icons-material/Remove';
import ShareIcon from '@mui/icons-material/Share';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modals from '../../../component/Modals';
import useAuth from '../../../hooks/useAuth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useDownload from '../../../hooks/useDownload';
import useShare from '../../../hooks/useShare';
import roundToDigit from '../../../utils/roundToDigit';

function ModifyTransaction() {
  const { role, user, SalesManagers, SalesOfficers } = useAuth();
  const { downloadPdf } = useDownload();
  const { sharePdf, isLoadingPdf, getFile, navigatorShare } = useShare();
  const [refetch] = useOutletContext();
  const navigate = useNavigate();
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const editId = params.transactionId;

  // form data states
  const [orderedAt, setOrderedAt] = React.useState(dayjs());
  const [productsConfirmedAt, setProductsConfirmedAt] = React.useState(dayjs());
  const [transactionType, setTransactionType] = React.useState({ id: '' });
  const [sender, setSender] = React.useState({ id: '' });
  const [receiver, setReceiver] = React.useState({ id: '' });
  const [recordedBy, setRecordedBy] = React.useState({ id: '' });
  const [invoiceAmount, setInvoiceAmount] = React.useState(0);
  const [discountPercent, setDiscountPercent] = React.useState(0);

  // selection states
  const [transactionTypes, setTransactionTypes] = React.useState([]);
  const [senders, setSenders] = React.useState([]);
  const [receivers, setReceivers] = React.useState([]);
  const [employees, setEmployees] = React.useState([]);
  const [productsList, setProductsList] = React.useState([]);

  const [oldProducts, setOldProducts] = React.useState([]);
  const [oldInvoiceAmount, setOldInvoiceAmount] = React.useState(0);

  const [hasSenderLedger, setHasSenderLedger] = React.useState(false);
  const [hasReceiverLedger, setHasReceiverLedger] = React.useState(false);

  const [senderLedgerEntries, setSenderLedgerEntries] = React.useState([
    { key: 101, type: '', amount: '' },
  ]);
  const [receiverLedgerEntries, setReceiverLedgerEntries] = React.useState([
    { key: 102, type: '', amount: '' },
  ]);
  const [senderLedgerEntriesTotal, setSenderLedgerEntriesTotal] =
    React.useState(0);
  const [receiverLedgerEntriesTotal, setReceiverLedgerEntriesTotal] =
    React.useState(0);

  // Rendering triggers
  const [triggerX, setTriggerX] = React.useState(0);
  const [triggerY, setTriggerY] = React.useState(0);
  const [triggerZ, setTriggerZ] = React.useState(0);
  const [triggerSender, setTriggerSender] = React.useState(0);
  const [triggerReceiver, setTriggerReceiver] = React.useState(0);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isDateError1, setIsDateError1] = React.useState(false);
  const [isDateError2, setIsDateError2] = React.useState(false);

  const [showPreview, setShowPreview] = React.useState(false);
  const [previewData, setPreviewData] = React.useState({});

  const [productFilter, setProductFilter] = React.useState('');

  const handlePreview = () => {
    let hasError = false;

    const products = [];
    productsList?.forEach((p) => {
      if (p?.quantity > p?.stock) {
        hasError = true;
        p.hasError = true;
        p.isStockExceeded = true;
      }
      if (p?.quantity > 0) products.push(p);
    });

    const validSenderEntries = [];
    senderLedgerEntries.forEach((i) => {
      if (i.amount && i.type) {
        validSenderEntries.push(i);
      }
    });

    const validReceiverEntries = [];
    receiverLedgerEntries.forEach((i) => {
      if (i.amount && i.type) {
        validReceiverEntries.push(i);
      }
    });

    setPreviewData({
      hasError,
      transactionType,
      sender,
      receiver,
      recordedBy,
      orderedAt,
      productsConfirmedAt,
      invoiceAmount,
      discountPercent,
      products,
      senderLedgerEntries: validSenderEntries,
      receiverLedgerEntries: validReceiverEntries,
    });

    setShowPreview(true);
  };

  const handleSubmit = () => {
    const products = [];

    productsList?.forEach((p) => {
      if (p?.quantity > 0) products.push(p);
    });

    const validSenderEntries = [];
    senderLedgerEntries.forEach((i) => {
      const amount = parseFloat(i.amount);
      if (amount && i.type) {
        validSenderEntries.push({
          type: i.type,
          amount: roundToDigit(amount),
          date: productsConfirmedAt,
        });
      }
    });

    const validReceiverEntries = [];
    receiverLedgerEntries.forEach((i) => {
      const amount = parseFloat(i.amount);
      if (amount && i.type) {
        validReceiverEntries.push({
          type: i.type,
          amount: roundToDigit(amount),
          date: productsConfirmedAt,
        });
      }
    });

    axiosPrivate({
      method: editId ? 'patch' : 'post',
      url: editId
        ? `/api/v1/transaction/${editId}/confirm-products`
        : '/api/v1/transaction',
      data: {
        transactionType: transactionType?.id,
        sender: sender?.id,
        receiver: receiver?.id,
        recordedBy: recordedBy?.id,
        orderedAt,
        productsConfirmedAt,
        invoiceAmount,
        payableAmount: invoiceAmount,
        discountPercent,
        products,
        senderLedgerEntries: validSenderEntries,
        receiverLedgerEntries: validReceiverEntries,
      },
    })
      .then((res) => {
        refetch();
        toast.success(res?.data?.message);
        navigate('/transactions');
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      });
  };

  const handleQuantityChange = (id, quantity) => {
    setIsLoading(true);
    const products = [];
    productsList?.forEach((p) => {
      if (p?.id === id) {
        if (quantity > p?.stock) {
          quantity = p.stock;
        }
        if (quantity > 0) {
          p.quantity = quantity;
        } else {
          p.quantity = 0;
        }
      }
      products.push(p);
    });

    setProductsList(products);
    setTriggerY(Date.now());
  };

  const handleAddSenderLedgerEntry = () => {
    const entries = [
      ...senderLedgerEntries,
      {
        key: Date.now(),
        type: '',
        amount: '',
      },
    ];

    setSenderLedgerEntries(entries);
    setTriggerSender(Date.now());
  };

  const handleAddReceiverLedgerEntry = () => {
    const entries = [
      ...receiverLedgerEntries,
      {
        key: Date.now(),
        type: '',
        amount: '',
      },
    ];

    setReceiverLedgerEntries(entries);
    setTriggerReceiver(Date.now());
  };

  const handleRemoveSenderLedgerEntry = (key) => {
    const entries = [...senderLedgerEntries];
    const index = entries.findIndex((i) => i.key === key);
    entries.splice(index, 1);

    setSenderLedgerEntries(entries);
    setTriggerSender(Date.now());
  };

  const handleRemoveReceiverLedgerEntry = (key) => {
    const entries = [...receiverLedgerEntries];
    const index = entries.findIndex((i) => i.key === key);
    entries.splice(index, 1);

    setReceiverLedgerEntries(entries);
    setTriggerReceiver(Date.now());
  };

  const handleEditSenderLedgerEntryType = (key, value) => {
    const entries = [...senderLedgerEntries];
    const entry = entries.find((i) => i.key === key);
    entry.type = value;

    setSenderLedgerEntries(entries);
    setTriggerSender(Date.now());
  };

  const handleEditReceiverLedgerEntryType = (key, value) => {
    const entries = [...receiverLedgerEntries];
    const entry = entries.find((i) => i.key === key);
    entry.type = value;

    setReceiverLedgerEntries(entries);
    setTriggerReceiver(Date.now());
  };

  const handleEditSenderLedgerEntryAmount = (key, value) => {
    const entries = [...senderLedgerEntries];
    const entry = entries.find((i) => i.key === key);
    entry.amount = value;

    setSenderLedgerEntries(entries);
    setTriggerSender(Date.now());
  };

  const handleEditReceiverLedgerEntryAmount = (key, value) => {
    const entries = [...receiverLedgerEntries];
    const entry = entries.find((i) => i.key === key);
    entry.amount = value;

    setReceiverLedgerEntries(entries);
    setTriggerReceiver(Date.now());
  };

  // Calculate price
  React.useEffect(() => {
    let amount = 0;
    const products = [];
    productsList?.forEach((p) => {
      const product = { ...p };
      if (p?.quantity > 0) {
        const price = p[transactionType?.priceType];
        const quantity = p.quantity;
        const quantityPerCarton = parseInt(p?.quantityPerCarton, 10);

        const quantityCartons = Math.floor(quantity / quantityPerCarton);
        const quantityPieces = quantity % quantityPerCarton;

        product.quantityDisplay = `Ordered: ${quantityCartons} ctn`;
        if (quantityPieces > 0) {
          product.quantityDisplay += ` ${quantityPieces} pcs`;
        }

        if (transactionType?.applyOffer) {
          if (p?.offerType === 'free') {
            const freeItemsPerCarton = parseFloat(p?.freeItemsPerCarton);

            const freeQuantity = Math.floor(
              quantity / (quantityPerCarton / freeItemsPerCarton),
            );

            const freeCartons = Math.floor(freeQuantity / quantityPerCarton);

            product.freeQuantity = freeCartons * quantityPerCarton;

            const freeQuantityCartons = Math.floor(
              product.freeQuantity / quantityPerCarton,
            );
            const freeQuantityPieces = product.freeQuantity % quantityPerCarton;

            let freeDisplay = `${freeQuantityCartons} ctn`;
            if (freeQuantityPieces > 0) {
              freeDisplay += ` ${freeQuantityPieces} pcs`;
            }

            const totalCarton = Math.floor(
              (quantity + product.freeQuantity) / quantityPerCarton,
            );
            const totalPieces =
              (quantity + product.freeQuantity) % quantityPerCarton;

            let totalDisplay = `${totalCarton} ctn`;
            if (totalPieces > 0) {
              totalDisplay += ` ${totalPieces} pcs`;
            }

            product.quantityDisplay += `, Free: ${freeDisplay}, Total: ${totalDisplay}`;
          } else if (
            p?.offerType === 'gift' &&
            p?.minimumOrderQuantityForOffer > 0
          ) {
            const giftQuantity = Math.floor(
              quantity / p.minimumOrderQuantityForOffer,
            );

            product.giftQuantity = giftQuantity;
            product.quantityDisplay += `, Gift items: ${p?.giftItemDescription} (x${giftQuantity})`;
          }
        } else {
          product.freeQuantity = 0;
          product.giftQuantity = 0;
        }

        if (price) {
          let total = price * quantity;
          if (discountPercent > 0) {
            total -= (total * discountPercent) / 100;
          }

          product.totalPrice = roundToDigit(total);
          amount += total;

          product.priceDisplay = `Price: (${quantity} x ${price}) - ${discountPercent}% = ${product.totalPrice}`;
        }
      } else {
        product.freeQuantity = 0;
        product.giftQuantity = 0;
        product.totalPrice = 0;
        product.priceDisplay = '';
        product.quantityDisplay = '';
      }
      products.push(product);
    });

    setProductsList(products);
    setInvoiceAmount(roundToDigit(amount));
    setTriggerZ(Date.now());
    setIsLoading(false);
  }, [
    transactionType?.priceType,
    transactionType?.applyOffer,
    discountPercent,
    triggerX,
    triggerY,
  ]);

  // Get old transaction data
  React.useEffect(() => {
    if (editId) {
      setIsLoading(true);
      axiosPrivate
        .get(`/api/v1/transaction/${editId}?populate=yes`)
        .then((res) => {
          const op = {};
          res?.data?.data?.products?.forEach((p) => {
            const product = { ...p, product: p?.product?.id };
            op[p?.product?.id] = product;
          });
          setOrderedAt(new Date(res?.data?.data?.orderedAt || Date.now()));
          setTransactionType(res?.data?.data?.transactionType);
          setSender(res?.data?.data?.sender);
          setReceiver(res?.data?.data?.receiver);
          setRecordedBy(res?.data?.data?.recordedBy);
          setDiscountPercent(res?.data?.data?.discountPercent);
          setOldInvoiceAmount(res?.data?.data?.invoiceAmount);
          setOldProducts(op);
        })
        .catch((err) => {
          toast.warn(
            `Failed to get transacton data. ${err?.response?.data?.message}`,
          );
        });
    }
  }, [editId]);

  // Get Receiver's Discount Percent
  React.useEffect(() => {
    setIsLoading(true);
    if (receiver?.customerClass?.id) {
      axiosPrivate
        .get(`/api/v1/customer/class/${receiver?.customerClass?.id}`)
        .then((res) => {
          let dp =
            parseFloat(res?.data?.data?.discountPercent) +
            parseFloat(transactionType?.discountPercent);

          if (dp) {
            setDiscountPercent(dp);
          }
        })
        .catch((err) => {
          toast.warn(
            `Failed to get receiver's discount. ${err?.response?.data?.message}`,
          );
        });
    }
  }, [receiver?.customerClass?.id]);

  // Get all products and sender stock
  React.useEffect(() => {
    setIsLoading(true);
    async function fetchProducts() {
      let allProducts = [];
      let stock = {};
      try {
        if (sender?.id) {
          const res1 = await axiosPrivate.get(
            '/api/v1/product?populate=yes&limit=none&isActive=true&isOwn=true&sort=name',
          );
          const res2 = await axiosPrivate.get(
            `/api/v1/customer/${sender?.id}/stock`,
          );
          res2?.data?.data?.products?.forEach((p) => {
            if (transactionType?.productCondition === 'intact') {
              stock[p?.product?.id] = p.intactQuantity;
            } else if (transactionType?.productCondition === 'damaged') {
              stock[p?.product?.id] = p.damagedQuantity;
            }
          });

          allProducts = res1?.data?.data;
        }
      } catch (err) {
        toast.warn(err?.response?.data?.message);
      } finally {
        const list = [];

        allProducts?.forEach((p) => {
          const stockCartons = Math.floor(stock[p.id] / p?.quantityPerCarton);
          const stockPieces = stock[p.id] % p?.quantityPerCarton;

          let stockDisplay = `Stock: ${stockCartons || 0} ctn`;
          if (stockPieces > 0) {
            stockDisplay += ` ${stockPieces} pcs`;
          }

          let sp = {
            ...p,
            product: p.id,
            quantity: 0,
            freeQuantity: 0,
            giftQuantity: 0,
            priceDisplay: '',
            quantityDisplay: '',
            totalPrice: 0,
            stock:
              editId && sender?.customerType?.hasStock
                ? stock[p.id] || 0
                : 999999,
            stockDisplay,
          };

          if (oldProducts[p.id]?.quantity) {
            sp = { ...sp, ...oldProducts[p.id] };
          }

          list.push(sp);
        });

        setProductsList(list);
        setTriggerX(Date.now());
      }
    }
    fetchProducts();
  }, [sender?.id, transactionType?.productCondition]);

  // Set has sender ledger
  React.useEffect(() => {
    setHasSenderLedger(sender?.customerType?.hasLedger);
  }, [sender?.customerType?.hasLedger]);

  // Set has receiver ledger
  React.useEffect(() => {
    setHasReceiverLedger(receiver?.customerType?.hasLedger);
  }, [receiver?.customerType?.hasLedger]);

  // Set sender ledger entry total
  React.useEffect(() => {
    setSenderLedgerEntriesTotal(
      roundToDigit(
        senderLedgerEntries.reduce((accumulator, current) => {
          const amount = parseFloat(current?.amount);
          if (amount && current?.type) {
            return accumulator + amount;
          }
          return accumulator;
        }, 0),
      ),
    );
  }, [triggerSender]);

  // Set receiver ledger entry total
  React.useEffect(() => {
    setReceiverLedgerEntriesTotal(
      roundToDigit(
        receiverLedgerEntries.reduce((accumulator, current) => {
          const amount = parseFloat(current?.amount);
          if (amount && current?.type) {
            return accumulator + amount;
          }
          return accumulator;
        }, 0),
      ),
    );
  }, [triggerReceiver]);

  // Get existing sender ledger entries
  React.useEffect(() => {
    if (sender?.id && editId) {
      axiosPrivate
        .get(
          `/api/v1/ledger?limit=none&sort=type&customer=${sender?.id}&transactionRef=${editId}`,
        )
        .then((res) => {
          const oldEntries = [];
          res?.data?.data?.forEach((e) => {
            oldEntries.push({
              key: e?.id,
              type: e?.type,
              amount: e?.totalAmount,
            });
          });

          if (oldEntries?.length > 0) {
            setSenderLedgerEntries(oldEntries);
            setTriggerSender(Date.now());
          }
        })
        .catch((err) => {
          toast.warn(err?.response?.data?.message);
        });
    }
  }, [sender?.id]);

  // Get existing receiver ledger entries
  React.useEffect(() => {
    if (receiver?.id && editId) {
      axiosPrivate
        .get(
          `/api/v1/ledger?limit=none&sort=type&customer=${receiver?.id}&transactionRef=${editId}`,
        )
        .then((res) => {
          const oldEntries = [];
          res?.data?.data?.forEach((e) => {
            oldEntries.push({
              key: e?.id,
              type: e?.type,
              amount: e?.totalAmount,
            });
          });

          if (oldEntries?.length > 0) {
            setReceiverLedgerEntries(oldEntries);
            setTriggerReceiver(Date.now());
          }
        })
        .catch((err) => {
          toast.warn(err?.response?.data?.message);
        });
    }
  }, [receiver?.id]);

  // Get all transaction types
  React.useEffect(() => {
    axiosPrivate
      .get(
        '/api/v1/transaction/type?populate=yes&limit=none&sort=transactionType&isActive=true',
      )
      .then((res) => {
        if (res?.data?.data) {
          setTransactionTypes(res.data.data);
        }
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      });
  }, []);

  // Get all senders
  React.useEffect(() => {
    setSenders([]);
    axiosPrivate
      .get(
        `/api/v1/customer?populate=yes&limit=none&isActive=true&sort=code&customerType=${transactionType?.senderType?.id}`,
      )
      .then((res) => {
        if (res?.data?.data) {
          setSenders(res.data.data);
        }
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      });
  }, [transactionType?.senderType?.id]);

  // Get all receivers
  React.useEffect(() => {
    setReceivers([]);
    axiosPrivate
      .get(
        `/api/v1/customer?populate=yes&limit=none&isActive=true&sort=code&customerType=${transactionType?.receiverType?.id}&parentTerritory=${user?.employee?.territory?.id}`,
      )
      .then((res) => {
        if (res?.data?.data) {
          setReceivers(res.data.data);
        }
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      });
  }, [transactionType?.receiverType?.id, user?.employee?.territory?.id]);

  // Get all employees
  React.useEffect(() => {
    setEmployees([]);
    axiosPrivate
      .get('/api/v1/employee?populate=yes&limit=none&isActive=true&sort=code')
      .then((res) => {
        if (res?.data?.data) {
          setEmployees(res.data.data);
        }
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      });
  }, []);

  // Set Recorded By ID
  React.useEffect(() => {
    if (user?.employee?.id) {
      setRecordedBy(user?.employee);
    }
  }, [user?.employee?.id]);

  const productsControl = React.useMemo(() => {
    return productsList.map((p, i) => {
      if (!p.nameCode.toLowerCase().includes(productFilter.toLowerCase())) {
        return null;
      }

      // if (
      //   (i + 1)
      //     .toString()
      //     .toLowerCase()
      //     .indexOf(productFilter.toLowerCase()) === -1 &&
      //   p.nameCode.toLowerCase().indexOf(productFilter.toLowerCase()) === -1
      // )
      //   return '';

      let offer;
      switch (p?.offerType) {
        case 'free':
          offer = `Free ${roundToDigit(p?.freeItemsPerCarton, 5)} pcs / ctn`;
          break;
        case 'gift':
          offer = `Gift ${p?.giftItemDescription}`;
          break;
        default:
          offer = '';
          break;
      }

      const disabled = false; // Boolean(p.stock <= 0);
      const isStockExceeded = Boolean(p.stock < p.quantity);
      let shadow = '';
      if (isStockExceeded) {
        shadow = 'inset 8px 0px crimson';
      } else if (p.quantity > 0) {
        shadow = 'inset 8px 0px hotpink';
      }

      return (
        <Paper key={p.id} variant="outlined" sx={{ boxShadow: shadow }}>
          <Stack
            direction={{ xl: 'row' }}
            gap={2}
            sx={{
              padding: '16px 4px 8px 16px',
              justifyContent: 'space-between',
            }}>
            <Stack direction="column">
              <Typography variant="body2" fontWeight="bold" gutterBottom>
                {`${i + 1}. ${p.name} (${p.code})`}
              </Typography>
              <Typography
                variant="caption"
                fontWeight="bold"
                color="success.main"
                gutterBottom>
                {offer}
              </Typography>
              {p.stock < 999999 && (
                <Typography
                  variant="subtitle2"
                  color={isStockExceeded ? 'error.main' : 'primary.main'}
                  gutterBottom>
                  {p.stockDisplay}
                </Typography>
              )}
              <Typography variant="subtitle2" color="text.secondary">
                {p.priceDisplay || 'Price: 0'}
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                {p.quantityDisplay || 'Ordered: 0'}
              </Typography>
            </Stack>
            <Stack
              direction={{ md: 'row' }}
              gap={0.5}
              sx={{ alignItems: 'center' }}>
              <Stack direction={'row'}>
                <Button
                  disabled={disabled}
                  size="small"
                  sx={{ maxWidth: 64 }}
                  onClick={() => {
                    const q = p.quantity - p.quantityPerCarton;
                    handleQuantityChange(p.id, q);
                  }}>
                  -1 carton
                </Button>
                <IconButton
                  disabled={disabled}
                  color="primary"
                  aria-label="remove one"
                  onClick={() => {
                    const q = p.quantity - 1;
                    handleQuantityChange(p.id, q);
                  }}>
                  <RemoveIcon />
                </IconButton>
              </Stack>

              <TextField
                disabled={disabled}
                error={isStockExceeded}
                type="number"
                size="small"
                name="quantity"
                label="Packets"
                sx={{ maxWidth: 120 }}
                value={p.quantity}
                onChange={(e) => {
                  const q = parseInt(e.target.value, 10);
                  handleQuantityChange(p.id, q);
                }}
              />

              <Stack direction={'row'}>
                <IconButton
                  disabled={disabled}
                  color="primary"
                  aria-label="add one"
                  onClick={() => {
                    const q = p.quantity + 1;
                    handleQuantityChange(p.id, q);
                  }}>
                  <AddIcon />
                </IconButton>
                <Button
                  disabled={disabled}
                  size="small"
                  sx={{ maxWidth: 64 }}
                  onClick={() => {
                    const q = p.quantity + p.quantityPerCarton;
                    handleQuantityChange(p.id, q);
                  }}>
                  +1 carton
                </Button>
                <Button
                  disabled={disabled}
                  size="small"
                  sx={{ maxWidth: 64 }}
                  onClick={() => {
                    const q = p.quantity + p.quantityPerCarton * 10;
                    handleQuantityChange(p.id, q);
                  }}>
                  +10 carton
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
      );
    });
  }, [productsList.length, triggerZ, productFilter]);

  const ledgerEntryTypes = [
    { value: '', label: 'None' },
    { value: 'salary', label: 'Salary' },
    { value: 'transport', label: 'Transport' },
    { value: 'damage', label: 'Damage' },
    { value: 'return', label: 'Return' },
    { value: 'commission', label: 'Commission' },
    { value: 'other', label: 'Other funds' },
  ];

  const senderLedgerExtrasControl = React.useMemo(() => {
    return senderLedgerEntries.map((i) => (
      <Stack key={i.key} direction="row" gap={1} alignItems="center">
        <TextField
          select
          label="Type"
          size="small"
          value={i.type}
          onChange={(e) =>
            handleEditSenderLedgerEntryType(i.key, e.target.value)
          }
          sx={{ minWidth: 136 }}>
          {ledgerEntryTypes.map((i) => (
            <MenuItem key={i.label} dense disabled={!i.value} value={i.value}>
              {i.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          type="number"
          label="Amount"
          size="small"
          fullWidth
          value={i.amount}
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            handleEditSenderLedgerEntryAmount(i.key, e.target.value)
          }
        />

        <Tooltip title="Remove" placement="right">
          <IconButton
            aria-label="remove"
            size="small"
            color="error"
            edge="end"
            onClick={() => handleRemoveSenderLedgerEntry(i.key)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    ));
  }, [triggerSender]);

  const receiverLedgerExtrasControl = React.useMemo(() => {
    return receiverLedgerEntries.map((i) => (
      <Stack key={i.key} direction="row" gap={1} alignItems="center">
        <TextField
          select
          label="Type"
          size="small"
          value={i.type}
          onChange={(e) =>
            handleEditReceiverLedgerEntryType(i.key, e.target.value)
          }
          sx={{ minWidth: 136 }}>
          {ledgerEntryTypes.map((i) => (
            <MenuItem key={i.label} dense disabled={!i.value} value={i.value}>
              {i.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          type="number"
          label="Amount"
          size="small"
          fullWidth
          value={i.amount}
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            handleEditReceiverLedgerEntryAmount(i.key, e.target.value)
          }
        />

        <Tooltip title="Remove" placement="right">
          <IconButton
            aria-label="remove"
            size="small"
            color="error"
            edge="end"
            onClick={() => handleRemoveReceiverLedgerEntry(i.key)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    ));
  }, [triggerReceiver]);

  return (
    <>
      <Box sx={styleMain}>
        <Paper elevation={2} sx={{ p: 3 }}>
          <Stack gap={3}>
            <Autocomplete
              fullWidth
              disabled={transactionTypes.length < 1}
              options={transactionTypes}
              value={transactionType}
              isOptionEqualToValue={(option, value) => value?.id === option?.id}
              onChange={(event, value) => {
                setTransactionType(value);
                setSender({ id: '' });
                setReceiver({ id: '' });
              }}
              getOptionLabel={(option) =>
                option?.transactionType
                  ? `${option?.transactionType} (${option?.senderType?.customerType} to ${option?.receiverType?.customerType})`
                  : ''
              }
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Transaction type" />
              )}
            />
            <Typography variant="caption" textTransform="capitalize">
              Function : {transactionType?.function || ''}
              <br />
              Product condition : {transactionType?.productCondition || ''}
              <br />
              Apply trade offer : {transactionType?.applyOffer ? 'Yes' : 'No'}
              <br />
              Discount : {transactionType?.discountPercent || '0'}%
              <br />
            </Typography>
            <Autocomplete
              fullWidth
              disabled={senders.length < 1}
              options={senders}
              value={sender}
              isOptionEqualToValue={(option, value) => value?.id === option?.id}
              onChange={(event, value) => {
                setSender(value);
              }}
              getOptionLabel={(option) =>
                option?.name
                  ? `${option?.name} (${option?.code}) - ${option?.territory?.name}`
                  : ''
              }
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Sender" />
              )}
            />
            <Autocomplete
              fullWidth
              disabled={receivers.length < 1}
              options={receivers}
              value={receiver}
              isOptionEqualToValue={(option, value) => value?.id === option?.id}
              onChange={(event, value) => {
                setReceiver(value);
              }}
              getOptionLabel={(option) =>
                option?.name
                  ? `${option?.name} (${option?.code}) - ${option?.territory?.name}`
                  : ''
              }
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Receiver" />
              )}
            />
            <Autocomplete
              fullWidth
              disabled={editId || user?.employee?.id || employees.length < 1}
              options={employees}
              value={recordedBy}
              isOptionEqualToValue={(option, value) => value?.id === option?.id}
              onChange={(event, value) => {
                setRecordedBy(value);
              }}
              getOptionLabel={(option) =>
                option?.name
                  ? `${option?.name} (${option?.code}) - ${option?.territory?.name}`
                  : ''
              }
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Recorded by" />
              )}
            />

            {editId ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  disableFuture
                  fullWidth
                  label="Products Confirm Time"
                  inputFormat="DD/MM/YYYY hh:mm A"
                  views={['month', 'year', 'day', 'hours', 'minutes']}
                  value={productsConfirmedAt}
                  onError={(reason) => setIsDateError1(Boolean(reason))}
                  onChange={(newValue) => setProductsConfirmedAt(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            ) : (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  disabled
                  disableFuture
                  fullWidth
                  label="Order Time"
                  inputFormat="DD/MM/YYYY hh:mm A"
                  views={['month', 'year', 'day', 'hours', 'minutes']}
                  value={orderedAt}
                  onError={(reason) => setIsDateError2(Boolean(reason))}
                  onChange={(newValue) => setOrderedAt(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            )}
          </Stack>
        </Paper>
        <Paper elevation={2}>
          <Stack
            gap={2}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ px: 3, pt: 2, pb: 1 }}>
            <Typography variant="h6" color="primary">
              Products
            </Typography>
            <TextField
              width
              size="small"
              type="text"
              placeholder="Search products"
              value={productFilter}
              onChange={(e) => setProductFilter(e.target.value)}
            />
          </Stack>
          <Box sx={{ height: { md: 480 }, overflow: 'auto' }}>
            <Stack gap={2} sx={{ p: 1 }}>
              {productsControl}
            </Stack>
          </Box>
        </Paper>
      </Box>

      {editId && (hasSenderLedger || hasReceiverLedger) ? (
        <Paper elevation={2} sx={{ p: 3, mb: 2 }}>
          <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
            <Grid item xs={12}>
              <Typography textAlign="center" color="primary.main">
                Ledger Adjustments
              </Typography>
            </Grid>

            {hasSenderLedger ? (
              <Grid item xs={12} md={6}>
                <Stack
                  direction="column"
                  component={Paper}
                  variant="outlined"
                  sx={{ gap: 3, p: 2, alignItems: 'center' }}>
                  <Typography variant="subtitle2" textAlign="center">
                    {sender?.nameCode} - {sender?.territory?.name}
                  </Typography>

                  {senderLedgerExtrasControl}

                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<AddIcon />}
                    sx={{ maxWidth: 96 }}
                    onClick={handleAddSenderLedgerEntry}>
                    Add
                  </Button>
                </Stack>
              </Grid>
            ) : null}

            {hasReceiverLedger ? (
              <Grid item xs={12} md={6}>
                <Stack
                  direction="column"
                  component={Paper}
                  variant="outlined"
                  sx={{ gap: 3, p: 2, alignItems: 'center' }}>
                  <Typography variant="subtitle2" textAlign="center">
                    {receiver?.nameCode} - {receiver?.territory?.name}
                  </Typography>

                  {receiverLedgerExtrasControl}

                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<AddIcon />}
                    sx={{ maxWidth: 96 }}
                    onClick={handleAddReceiverLedgerEntry}>
                    Add
                  </Button>
                </Stack>
              </Grid>
            ) : null}
          </Grid>
        </Paper>
      ) : null}

      <Box>
        <Paper elevation={2} sx={{ p: 3 }}>
          <Typography variant="h6" color="primary" sx={{ pb: 2 }}>
            Order summary
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Stack direction="column" gap={2} px={2} pb={2}>
                {editId && (
                  <Stack direction="row" justifyContent="space-between" gap={2}>
                    <Typography>Pervious invoice Amount</Typography>
                    <Typography fontWeight="bold">
                      {oldInvoiceAmount}
                    </Typography>
                  </Stack>
                )}
                <Stack direction="row" justifyContent="space-between" gap={2}>
                  <Typography>
                    {editId ? 'Updated invoice Amount' : 'Invoice Amount'}
                  </Typography>
                  <Typography fontWeight="bold">{invoiceAmount}</Typography>
                </Stack>
                {
                  //[...SalesManagers, ...SalesOfficers,].includes(role)
                  true ? null : (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      gap={2}>
                      <Typography>Ledger Balance</Typography>
                      <Typography
                        fontWeight="bold"
                        color={
                          receiver?.ledgerBalance < invoiceAmount
                            ? 'red'
                            : 'green'
                        }>
                        {receiver?.ledgerBalance}
                      </Typography>
                    </Stack>
                  )
                }
                {senderLedgerEntriesTotal ? (
                  <Stack direction="row" justifyContent="space-between" gap={2}>
                    <Typography>{sender?.name}'s Ledger Adjustment</Typography>
                    <Typography
                      fontWeight="bold"
                      color={senderLedgerEntriesTotal < 0 ? 'red' : 'green'}>
                      {senderLedgerEntriesTotal < 0 ? '' : '+'}
                      {senderLedgerEntriesTotal}
                    </Typography>
                  </Stack>
                ) : null}
                {receiverLedgerEntriesTotal ? (
                  <Stack direction="row" justifyContent="space-between" gap={2}>
                    <Typography>
                      {receiver?.name}'s Ledger Adjustment
                    </Typography>
                    <Typography
                      fontWeight="bold"
                      color={receiverLedgerEntriesTotal < 0 ? 'red' : 'green'}>
                      {receiverLedgerEntriesTotal < 0 ? '' : '+'}
                      {receiverLedgerEntriesTotal}
                    </Typography>
                  </Stack>
                ) : null}
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                type="number"
                label="Discount (%)"
                fullWidth
                value={discountPercent}
                onChange={(e) => {
                  const a = parseFloat(e.target.value);
                  if (a > 0) setDiscountPercent(a);
                  else setDiscountPercent(0);
                }}
                sx={{ mb: 3 }}
              />
              <TextField
                type="number"
                label="Invoice Amount"
                fullWidth
                value={invoiceAmount}
                onChange={(e) => {
                  const a = parseFloat(e.target.value);
                  if (a > 0) setInvoiceAmount(a);
                  else setInvoiceAmount(0);
                }}
                sx={{ mb: 3 }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Box sx={{ pt: 2, pb: 4, textAlign: 'center' }}>
        <Button
          disabled={isLoading}
          size="large"
          color="primary"
          variant="contained"
          onClick={handlePreview}>
          Preview
        </Button>
      </Box>

      <Modals
        title="Preview Transactions"
        show={showPreview}
        onSave={() => setShowPreview(false)}
        onHide={() => setShowPreview(false)}
        width="sm"
        fullWidth
        form>
        <Box id="transaction-view">
          <Typography variant="subtitle2" color="primary">
            Type:
          </Typography>
          <Typography gutterBottom>
            {previewData?.transactionType?.transactionType}
          </Typography>

          <Typography variant="subtitle2" color="primary">
            Sender:
          </Typography>
          <Typography gutterBottom>
            {previewData?.sender?.name} ({previewData?.sender?.code}) -
            {previewData?.sender?.territory?.name}
          </Typography>

          <Typography variant="subtitle2" color="primary">
            Receiver:
          </Typography>
          <Typography gutterBottom>
            {previewData?.receiver?.name} ({previewData?.receiver?.code}) -
            {previewData?.receiver?.territory?.name}
          </Typography>

          <Typography variant="subtitle2" color="primary">
            Recorded By:
          </Typography>
          <Typography gutterBottom>
            {previewData?.recordedBy?.name} ({previewData?.recordedBy?.code}) -
            {previewData?.recordedBy?.territory?.name}
          </Typography>

          <Typography variant="subtitle2" color="primary">
            Order Time:
          </Typography>
          <Typography gutterBottom>
            {previewData.orderedAt
              ? dayjs(previewData.orderedAt).format('DD/MM/YYYY hh:mm A')
              : ''}
          </Typography>

          {editId && (
            <>
              <Typography variant="subtitle2" color="primary">
                Products Confirm Time:
              </Typography>
              <Typography gutterBottom>
                {previewData.productsConfirmedAt
                  ? dayjs(previewData.productsConfirmedAt).format(
                      'DD/MM/YYYY hh:mm A',
                    )
                  : ''}
              </Typography>
            </>
          )}

          <Typography variant="subtitle2" color="primary">
            Products:
          </Typography>

          <Stack direction={'column'} gap={2} sx={{ pb: 2 }}>
            {previewData?.products?.map((p, index) => (
              <Paper
                variant="outlined"
                sx={{ p: 1, borderColor: p.hasError ? 'red' : null }}>
                <Typography fontWeight="bold">{`${index + 1}. ${p.name} (${
                  p.code
                })`}</Typography>
                {p.stock < 999999 && (
                  <Typography
                    variant="subtitle2"
                    color={p.isStockExceeded ? 'error.main' : 'primary.main'}
                    gutterBottom>
                    {p.stockDisplay}
                  </Typography>
                )}
                <Typography>{p.priceDisplay}</Typography>
                <Typography>{p.quantityDisplay}</Typography>
              </Paper>
            ))}
          </Stack>

          <Typography variant="subtitle2" color="primary">
            Invoice Amount:
          </Typography>
          <Typography gutterBottom>{previewData.invoiceAmount}</Typography>

          {senderLedgerEntriesTotal ? (
            <>
              <Typography variant="subtitle2" color="primary">
                {sender?.name}'s Ledger Adjustment:
              </Typography>
              {previewData?.senderLedgerEntries?.map((i) => (
                <Typography key={i?.key} textTransform="capitalize">
                  {i?.type}: {i?.amount}
                </Typography>
              ))}
              <Typography fontWeight="bold" gutterBottom>
                Total: {senderLedgerEntriesTotal < 0 ? '' : '+'}
                {senderLedgerEntriesTotal}
              </Typography>
            </>
          ) : null}

          {receiverLedgerEntriesTotal ? (
            <>
              <Typography variant="subtitle2" color="primary">
                {receiver?.name}'s Ledger Adjustment:
              </Typography>
              {previewData?.receiverLedgerEntries?.map((i) => (
                <Typography key={i?.key} textTransform="capitalize">
                  {i?.type}: {i?.amount}
                </Typography>
              ))}
              <Typography fontWeight="bold" gutterBottom>
                Total: {receiverLedgerEntriesTotal < 0 ? '' : '+'}
                {receiverLedgerEntriesTotal}
              </Typography>
            </>
          ) : null}
        </Box>

        <br />

        {previewData.hasError ? (
          <Typography
            variant="subtitle1"
            color="error"
            textAlign="center"
            fontWeight="bold">
            Please check all errors before saving
          </Typography>
        ) : null}

        <Stack direction={{ md: 'row' }} gap={2}>
          <Button
            fullWidth
            disabled={
              isLoading ||
              isDateError1 ||
              isDateError2 ||
              Boolean(previewData?.products?.length < 1)
            }
            size="large"
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            sx={{ flex: 1 }}>
            Save transaction
          </Button>

          <Stack direction="row" gap={2}>
            {!getFile ? (
              <Button
                disabled={
                  Boolean(isLoading || previewData?.products?.length < 1) ||
                  isLoadingPdf
                }
                color="primary"
                variant="outlined"
                startIcon={<PictureAsPdfIcon />}
                onClick={() =>
                  sharePdf(
                    'transaction-view',
                    `Transactions_orderDate:${dayjs(
                      previewData.orderedAt,
                    ).format('DD/MM/YYYY hh:mm A')}`,
                    675,
                    'downloadable',
                  )
                }
                sx={{ flex: 1 }}>
                {isLoadingPdf ? 'Preparing...' : 'Prepare PDF'}
              </Button>
            ) : (
              <Button
                disabled={!getFile}
                color="success"
                variant="outlined"
                startIcon={<ShareIcon />}
                onClick={() => navigatorShare(getFile)}>
                Share PDF
              </Button>
            )}
            <IconButton
              onClick={() =>
                downloadPdf(
                  'transaction-view',
                  `Transactions_orderDate:${dayjs(previewData.orderedAt).format(
                    'DD/MM/YYYY hh:mm A',
                  )}`,
                  675,
                )
              }
              sx={{ border: 1, borderRadius: 1 }}>
              <DownloadIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Modals>
    </>
  );
}

export default ModifyTransaction;

// style

const styleMain = {
  display: 'grid',
  gridTemplateColumns: { lg: '5fr 7fr', md: '1fr' },
  gap: '10px',
  marginBottom: '10px',
};
