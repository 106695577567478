import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box, Checkbox, ListItemText } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CustomMultiselect({ lists, state, setState }) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log(value);
    setState(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <FormControl fullWidth sx={styles}>
      <Select
        multiple
        value={state}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {selected.map((value) => (
              <span key={value}>{value}</span>
            ))}
          </Box>
        )}
        MenuProps={MenuProps}>
        {lists?.length < 1 ? <MenuItem disabled>No Data Found</MenuItem> : null}
        {lists
          .sort((a, b) => parseFloat(a) - parseFloat(b))
          .map((name) => (
            <MenuItem key={name} value={name} sx={listStyle}>
              <Checkbox checked={state.indexOf(name) > -1} size="small" />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

const styles = {
  '& .MuiInputBase-root': {
    background: '#fff',
    fontSize: '13px',
    borderRadius: 0,
    '& .MuiSelect-select': {
      padding: '0px 3px',
      maxHeight: '60px',
      overflow: 'auto',
    },
    fieldset: {
      border: '1px solid #80808078',
      //   bottom: 1,
      color: 'white',
    },
  },
};

const listStyle = {
  minHeight: '26px',
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: '3px',
  paddingRight: '3px',
  span: {
    padding: '0px 3px',
  },
  '& .MuiListItemText-root': {
    '& .MuiTypography-root': {
      fontSize: '13px',
    },
  },
};
