import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useNavigate, useParams } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom';
import HeadingTitle from '../../../component/HeadingTitle';

export default function ModifiedTerritories() {
  const [refetch] = useOutletContext();
  const params = useParams();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const editId = params?.territoryID;

  // selection states
  const [territoyTypes, setTerritoyTypes] = useState([]);
  const [parentTerritories, setParentTerritories] = useState([]);

  // form states
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [address, setAddress] = useState('');
  const [territoryType, setTerritoryType] = useState({});
  const [parentTerritory, setParentTerritory] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  // get edit territry data
  useEffect(() => {
    if (editId) {
      setIsLoading(true);
      axiosPrivate
        .get(`/api/v1/territory/${editId}?populate=yes`)
        .then((res) => {
          const data = res?.data?.data;
          setName(data?.name);
          setCode(data?.code);
          setAddress(data?.address);
          setTerritoryType(data?.territoryType);
          setParentTerritory(data?.parentTerritory);
        })
        .catch((error) => console.warn(error))
        .finally(() => setIsLoading(false));
    } else {
      setName('');
      setCode('');
      setAddress('');
      setTerritoryType({});
      setParentTerritory({});
    }
  }, [editId]);

  // get territory types
  useEffect(() => {
    axiosPrivate
      .get('/api/v1/territory/type?sort=rank&isActive=true')
      .then((res) => {
        setTerritoyTypes(res?.data?.data);
      })
      .catch((error) => console.warn(error));
  }, []);

  // get parent territories
  useEffect(() => {
    const index = territoyTypes.findIndex((item) => {
      return territoryType?.id === item?.id;
    });

    if (index !== -1) {
      const id = territoyTypes[index - 1]?.id;
      axiosPrivate
        .get(
          `/api/v1/territory?populate=yes&limit=none&sort=name&isActive=true&territoryType=${id}`,
        )
        .then((res) => {
          setParentTerritories(res?.data?.data);
        })
        .catch((error) => console.warn(error));
    }
  }, [territoryType?.id]);

  // get code
  useEffect(() => {
    if (!editId) {
      if (territoryType?.territoryType === 'Point/Town') {
        setCode(
          parentTerritory?.name
            ? `${parentTerritory?.parentTerritory?.name}-${parentTerritory?.name}-${name}`
            : '',
        ); //(region)-hq-(point/town)
      }
    }
  }, [territoryType, parentTerritory, name, editId]);

  const handleSetCode = () => {
    if (territoryType?.territoryType === 'Point/Town') {
      setCode(
        `${parentTerritory?.parentTerritory?.name}-${parentTerritory?.name}-${name}`,
      ); //(region)-hq-(point/town)
    }
  };

  const handleSubmit = async () => {
    if (parentTerritory.name === undefined) {
      toast.warn('Parent Territory is required');
    } else {
      await axiosPrivate({
        method: editId ? 'patch' : 'post',
        url: editId ? `/api/v1/territory/${editId}` : '/api/v1/territory',
        data: {
          territoryType: territoryType.id,
          parentTerritory: parentTerritory.id,
          code,
          name,
          address,
        },
      })
        .then((res) => {
          if (res) {
            if (editId) {
              toast.success(
                <div>
                  Location <b>"{res?.data?.data?.name}"</b> Updated Successfully
                </div>,
              );
              navigate('/territories');
              refetch();
            } else {
              toast.success(
                <div>
                  Location <b>"{res?.data?.data?.name}"</b> Added Successfully
                </div>,
              );
              navigate('/territories');
              refetch();
            }
          }
        })
        .catch((error) => {
          toast.warn(error?.response?.data?.message);
        });
    }
  };

  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center" height={1}>
        <CircularProgress />
        <Typography color="text.disabled">Loading</Typography>
      </Stack>
    );
  }

  return (
    <Box
      bgcolor="white.main"
      p={3}
      borderRadius={2}
      sx={{
        margin: '0 auto',
        marginTop: { md: 2, xs: 0 },
        width: { md: '40rem', xs: '100%' },
      }}>
      <HeadingTitle>{editId ? 'Edit' : 'Add'} Location</HeadingTitle>

      <Grid container spacing={3} mt={1}>
        <Grid item xs={12} md={6}>
          <Autocomplete
            disableClearable
            autoHighlight
            fullWidth
            options={territoyTypes}
            getOptionLabel={(option) => option.territoryType || ''}
            value={territoryType}
            onChange={(event, value) => {
              setTerritoryType(value);
              setParentTerritory({});
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Location Type" />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            disabled={territoryType?.id ? false : true}
            disableClearable
            autoHighlight
            fullWidth
            options={parentTerritories}
            getOptionLabel={(option) => option.nameCode || ''}
            value={parentTerritory}
            onChange={(event, value) => {
              setParentTerritory(value);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Parent Location" />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="text"
            label="Name"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">Code</InputLabel>
            <OutlinedInput
              fullWidth
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  {editId &&
                  territoryType.territoryType === 'Point/Town' &&
                  parentTerritory?.name ? (
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      sx={{ px: 0.25, py: 0.5, fontSize: 12 }}
                      onClick={handleSetCode}>
                      Generate
                    </Button>
                  ) : null}
                </InputAdornment>
              }
              label="Code"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="text"
            label="Address"
            variant="outlined"
            fullWidth
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>
      </Grid>

      <Stack direction="row" gap={2} justifyContent="flex-end" pt={3}>
        <Button
          variant="contained"
          color="error"
          sx={{ px: 3 }}
          onClick={() => navigate('/territories')}>
          Cancel
        </Button>
        <Button variant="contained" sx={{ px: 3 }} onClick={handleSubmit}>
          Save
        </Button>
      </Stack>
    </Box>
  );
}
