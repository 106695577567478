import React, { useMemo, useState } from 'react';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FactoryIcon from '@mui/icons-material/Factory';
import GroupsIcon from '@mui/icons-material/Groups';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PaymentsIcon from '@mui/icons-material/Payments';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import SellIcon from '@mui/icons-material/Sell';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import StoreIcon from '@mui/icons-material/Store';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Backdrop, Box, useMediaQuery } from '@mui/material';
import { LayoutContainer } from '../assets/style/Layout.jsx';
import useAuth from '../hooks/useAuth';
import useLayout from '../hooks/useLayout';
import Header from './Header';
import MobileNavigation from './MobileNavigation';
import Sidebar from './Sidebar';

export default function Layout({ children }) {
  const matches = useMediaQuery('(max-width:900px)');
  const { collapsemenu, setBackDrop, backDrop, left, setLeft } = useLayout();
  const { role, Admins, SalesManagers, SalesOfficers } = useAuth();
  // states
  const [filterMenu, setFilterMenu] = useState([{}]);

  const menuOptions = [
    {
      title: [...SalesManagers, ...SalesOfficers].includes(role)
        ? 'Forms'
        : 'Dashboard',
      icon: <DashboardIcon />,
      url: '/',
      unaccessible: ['Accounts', 'DO', 'IMS', 'Factory', 'SD'],
    },
    {
      title: 'Factory',
      icon: <FactoryIcon />,
      url: '/factory',
      unaccessible: [
        'Accounts',
        'DO',
        'IMS',
        ...SalesManagers,
        ...SalesOfficers,
        'SD',
      ],
    },
    {
      title: 'Geo Setup',
      icon: <LocationOnIcon />,
      url: '/territories',
      unaccessible: [
        'Accounts',
        'DO',
        'IMS',
        'Factory',
        ...SalesManagers,
        ...SalesOfficers,
        'SD',
      ],
    },
    {
      title: 'Authorization',
      icon: <AdminPanelSettingsIcon />,
      url: '/auth',
      unaccessible: [
        ...Admins,
        'Accounts',
        'DO',
        'IMS',
        'Factory',
        ...SalesManagers,
        ...SalesOfficers,
        'SD',
      ],
    },
    {
      title: 'Employees',
      icon: <GroupsIcon />,
      url: '/employees',
      unaccessible: [
        'Accounts',
        'DO',
        'IMS',
        'Factory',
        ...SalesManagers,
        ...SalesOfficers,
        'SD',
      ],
    },
    {
      title: 'Target',
      icon: <TrendingUpIcon />,
      url: '/target',
      unaccessible: ['Accounts', 'DO', 'Factory', 'SD'],
    },
    {
      title: 'SR Report',
      icon: <AssignmentIndIcon />,
      url: '/sr-report-entries',
      unaccessible: ['Accounts', 'DO', 'Factory', 'SD'],
    },
    {
      title: 'Payroll',
      icon: <AccountBalanceWalletIcon />,
      url: '/payroll-ledger',
      unaccessible: ['Accounts', 'DO', 'Factory', 'SD'],
    },
    {
      title: 'Customers',
      icon: <StoreIcon />,
      url: '/customers',
      unaccessible: ['IMS', 'Factory', ...SalesOfficers, 'SD'],
    },
    {
      title: 'Ph Stock',
      icon: <InventoryIcon />,
      url: '/physical-stocks',
      unaccessible: ['Accounts', 'Factory'],
    },
    {
      title: 'Collections',
      icon: <MonetizationOnIcon />,
      url: '/collections',
      unaccessible: ['IMS', 'Factory', ...SalesOfficers],
    },
    {
      title: 'Payments',
      icon: <PaymentsIcon />,
      url: '/payments',
      unaccessible: ['IMS', 'Factory', ...SalesManagers, ...SalesOfficers],
    },
    {
      title: 'SD Comm.',
      icon: <CopyAllIcon />,
      url: '/sd-commission',
      unaccessible: [
        'Super Admin',
        ...Admins,
        'IMS',
        'Factory',
        ...SalesManagers,
        ...SalesOfficers,
      ],
    },
    {
      title: 'Ledger',
      icon: <RequestPageIcon />,
      url: '/customer-ledger-report',
      unaccessible: [
        'Super Admin',
        ...Admins,
        // 'Accounts',
        // 'DO',
        'IMS',
        'Factory',
        ...SalesManagers,
        ...SalesOfficers,
      ],
    },
    {
      title: 'Lifting Plan',
      icon: <PriceCheckIcon />,
      url: '/lifting-plan-details',
      unaccessible: ['Accounts', 'DO', 'Factory', 'SD'],
    },
    {
      title: 'Market Price',
      icon: <SellIcon />,
      url: '/market-price',
      unaccessible: [
        'Accounts',
        'DO',
        'IMS',
        'Factory',
        ...SalesManagers,
        ...SalesOfficers,
        'SD',
      ],
    },
    {
      title: 'Products',
      icon: <LocalMallIcon />,
      url: '/products',
      unaccessible: [
        'Accounts',
        'DO',
        'IMS',
        'Factory',
        ...SalesManagers,
        ...SalesOfficers,
        'SD',
      ],
    },
    {
      title: 'Transactions',
      icon: <ShoppingCartCheckoutIcon />,
      url: '/transactions',
      unaccessible: ['IMS', 'Factory', ...SalesOfficers],
    },
    /* {
      title: 'Damages',
      icon: <BrokenImageIcon />,
      url: '/damages',
      unaccessible: ['IMS', 'Factory', ...SalesOfficers, 'SD'],
    }, */
    /* {
      title: 'Transfers',
      icon: <SwapHorizontalCircleIcon />,
      url: '/transfers',
      unaccessible: ['IMS', 'Factory', ...SalesOfficers],
    }, */
    /* {
      title: 'IMS Report',
      icon: <SpellcheckIcon />,
      url: '/customer-audited-ims',
      unaccessible: [
        ...Admins,
        'Accounts',
        'DO',
        'IMS',
        'Factory',
        ...SalesManagers,
        ...SalesOfficers,
      ],
    }, */
    {
      title: 'Settings',
      icon: <SettingsIcon />,
      url: '/settings',
      unaccessible: [
        ...Admins,
        'Accounts',
        'DO',
        'IMS',
        'Factory',
        ...SalesManagers,
        ...SalesOfficers,
        'SD',
      ],
    },
  ];

  useMemo(() => {
    const results = menuOptions.filter(
      (menu) => !menu?.unaccessible?.includes(role),
    );
    setFilterMenu(results);
  }, []);

  return (
    <>
      <LayoutContainer left={left}>
        <Backdrop
          sx={{ color: '#fff', zIndex: 92, display: { md: 'none' } }}
          open={backDrop}
          onClick={() => {
            setBackDrop(false);
            setLeft(-1000);
          }}
        />
        <Box
          className="leftBox"
          sx={{
            minWidth: `${collapsemenu ? 'initial' : '170px'}`,
            width: `${collapsemenu ? '55px' : 'initial'}`,
          }}>
          <Sidebar options={filterMenu} />
        </Box>

        {matches && <MobileNavigation options={filterMenu} />}

        <Box className="rightBox" sx={{ paddingBottom: { md: 0, xs: 8 } }}>
          <Header />
          <Box className="childrenContainer">
            <Box>{children}</Box>
          </Box>
        </Box>
      </LayoutContainer>
    </>
  );
}
