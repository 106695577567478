import React, { useState } from 'react';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useExpiredSession from '../../../hooks/useExpiredSession';
import { offerTypeLabels, unitLabels } from '../../../staticData/ProductUnits';
import roundToDigit from '../../../utils/roundToDigit';
import useAuth from '../../../hooks/useAuth';

export default function ModifyProduct() {
  const navigate = useNavigate();
  const params = useParams();
  const [refetch] = useOutletContext();
  const { role, Admins } = useAuth();
  const expired = useExpiredSession();
  const axiosPrivate = useAxiosPrivate();

  const editId = params.productId;

  // form data states
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [productCategory, setProductCategory] = useState({});
  const [productCompany, setProductCompany] = useState({});
  const [isOwn, setIsOwn] = useState(true);
  const [quantityPerCarton, setQuantityPerCarton] = useState('');
  const [dealerPrice, setDealerPrice] = useState('');
  const [dsrPrice, setDsrPrice] = useState('');
  const [tradePrice, setTradePrice] = useState('');
  const [maxRetailPrice, setMaxRetailPrice] = useState('');
  const [unitOfMeasurement, setUnitOfMeasurement] = useState('pcs');
  const [unitPerPacket, setUnitPerPacket] = useState('');
  const [weightPerPacket, setWeightPerPacket] = useState('');
  const [offerType, setOfferType] = useState('none');
  const [freeItemsPerCarton, setFreeItemsPerCarton] = useState(0);
  const [minimumOrderQuantityForOffer, setMinimumOrderQuantityForOffer] =
    useState(0);
  const [giftItemDescription, setGiftItemDescription] = useState('');
  const [description, setDescription] = useState('');
  const [isActive, setIsActive] = useState(true);

  const [photos, setPhotos] = useState([]);
  const [oldPhotos, setOldPhotos] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [companiesList, setCompaniesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // get edit product data
  React.useEffect(() => {
    if (editId) {
      setIsLoading(true);
      axiosPrivate
        .get(`/api/v1/product/${editId}?populate=yes`)
        .then((res) => {
          const info = res?.data?.data;
          const qpc = parseFloat(info?.quantityPerCarton);

          setCode(info?.code);
          setName(info?.name);
          setProductCategory(info?.productCategory);
          setProductCompany(info?.productCompany);
          setIsOwn(info?.isOwn);
          setQuantityPerCarton(info?.quantityPerCarton);
          setDealerPrice(roundToDigit(parseFloat(info?.dealerPrice) * qpc));
          setDsrPrice(roundToDigit(parseFloat(info?.dsrPrice) * qpc));
          setTradePrice(roundToDigit(parseFloat(info?.tradePrice) * qpc));
          setMaxRetailPrice(info?.maxRetailPrice);
          setUnitOfMeasurement(info?.unitOfMeasurement);
          setUnitPerPacket(info?.unitPerPacket);
          setWeightPerPacket(info?.weightPerPacket);
          setOfferType(info?.offerType);
          setFreeItemsPerCarton(info?.freeItemsPerCarton);
          setMinimumOrderQuantityForOffer(info?.minimumOrderQuantityForOffer);
          setGiftItemDescription(info?.giftItemDescription);
          setDescription(info?.description);
          setIsActive(info?.isActive);
          setOldPhotos(info?.photos);
        })
        .catch((error) => expired(error))
        .finally(() => setIsLoading(false));
    } else {
      setCode('');
      setName('');
      setProductCategory({});
      setProductCompany({});
      setIsOwn(true);
      setQuantityPerCarton('');
      setDealerPrice('');
      setDsrPrice('');
      setTradePrice('');
      setMaxRetailPrice('');
      setUnitOfMeasurement('pcs');
      setUnitPerPacket('');
      setWeightPerPacket('');
      setOfferType('none');
      setFreeItemsPerCarton(0);
      setMinimumOrderQuantityForOffer(0);
      setGiftItemDescription('');
      setDescription('');
      setIsActive(true);
      setOldPhotos([]);
    }
  }, [editId]);

  // get product category
  React.useEffect(() => {
    axiosPrivate
      .get(
        '/api/v1/product/category?limit=none&sort=productCategory&isActive=true',
      )
      .then((res) => {
        setCategoriesList(res?.data?.data);
      })
      .catch((error) => expired(error));
  }, []);

  // get product company
  React.useEffect(() => {
    axiosPrivate
      .get(
        '/api/v1/product/company?limit=none&sort=productCompany&isActive=true',
      )
      .then((res) => {
        setCompaniesList(res?.data?.data);
      })
      .catch((error) => expired(error));
  }, []);

  // Set MOQ for free offer
  React.useEffect(() => {
    const qpc = parseFloat(quantityPerCarton);
    const fpc = parseFloat(freeItemsPerCarton);

    switch (offerType) {
      case 'free':
        if (!Number.isNaN(qpc) && !Number.isNaN(fpc)) {
          setMinimumOrderQuantityForOffer(Math.ceil(qpc / fpc));
        } else {
          setMinimumOrderQuantityForOffer(0);
        }
        setGiftItemDescription('');
        break;

      case 'gift':
        setFreeItemsPerCarton(0);
        break;

      default:
        setMinimumOrderQuantityForOffer(0);
        setFreeItemsPerCarton(0);
        setGiftItemDescription('');
        break;
    }
  }, [offerType, freeItemsPerCarton, quantityPerCarton]);

  const handleSubmit = () => {
    const qpc = parseFloat(quantityPerCarton);
    const dsrp = parseFloat(dsrPrice);
    const dp = parseFloat(dealerPrice);
    const tp = parseFloat(tradePrice);

    if (
      Number.isNaN(qpc) ||
      Number.isNaN(dsrp) ||
      Number.isNaN(dp) ||
      Number.isNaN(tp)
    ) {
      toast.warn(
        'Quantity Per Carton, DSR Price, Dealer Price, Trade Price required',
      );
    } else {
      setIsLoading(true);

      const data = new FormData();

      if (code) {
        data.append('code', code);
      }
      if (name) {
        data.append('name', name);
      }
      if (productCategory?.id) {
        data.append('productCategory', productCategory?.id);
      }
      if (productCompany?.id) {
        data.append('productCompany', productCompany?.id);
      }
      if (isOwn) {
        data.append('isOwn', isOwn);
      }
      if (qpc) {
        data.append('quantityPerCarton', qpc);
      }
      if (dp) {
        data.append('dealerPrice', roundToDigit(dp / qpc, 5));
      }
      if (dsrp) {
        data.append('dsrPrice', roundToDigit(dsrp / qpc), 5);
      }
      if (tp) {
        data.append('tradePrice', roundToDigit(tp / qpc, 5));
      }
      if (maxRetailPrice) {
        data.append('maxRetailPrice', maxRetailPrice);
      }
      if (unitOfMeasurement) {
        data.append('unitOfMeasurement', unitOfMeasurement);
      }
      if (unitPerPacket) {
        data.append('unitPerPacket', unitPerPacket);
      }
      if (weightPerPacket) {
        data.append('weightPerPacket', weightPerPacket);
      }
      if (offerType) {
        data.append('offerType', offerType);
      }
      if (freeItemsPerCarton) {
        data.append('freeItemsPerCarton', freeItemsPerCarton);
      }
      if (minimumOrderQuantityForOffer) {
        data.append(
          'minimumOrderQuantityForOffer',
          minimumOrderQuantityForOffer,
        );
      }
      if (offerType === 'gift') {
        if (giftItemDescription) {
          data.append('giftItemDescription', giftItemDescription);
        }
      } else {
        data.append('giftItemDescription', 'none');
      }
      if (description) {
        data.append('description', description);
      }
      if (isActive) {
        data.append('isActive', isActive);
      }
      photos?.forEach((p) => {
        if (p.photo instanceof File) {
          data.append('photos', p.photo);
        }
      });

      axiosPrivate({
        method: editId ? 'patch' : 'post',
        url: editId ? `/api/v1/product/${editId}` : '/api/v1/product',
        data: data,
      })
        .then((res) => {
          toast.success(res?.data?.message);
          refetch();
          navigate('/products');
        })
        .catch((error) => expired(error))
        .finally(() => setIsLoading(false));
    }
  };

  const oldPhotosPreview = React.useMemo(() => {
    return oldPhotos?.map((p, i) => (
      <Grid key={p} item xs={12} sm={6} lg={4} xl={3}>
        <Card>
          <CardMedia
            component="img"
            height="200"
            image={p}
            alt={`Photo ${i + 1}`}
          />
        </Card>
      </Grid>
    ));
  }, [oldPhotos?.length]);

  const photosPreview = React.useMemo(() => {
    return photos?.map((p) => {
      if (!(p.photo instanceof File)) return undefined;

      return (
        <Grid key={p.id} item xs={12} sm={6} lg={4} xl={3}>
          <Card>
            <CardHeader
              subheader={p.photo?.name}
              subheaderTypographyProps={{ variant: 'caption' }}
              action={
                <IconButton
                  size="small"
                  color="error"
                  aria-label="remove photo"
                  onClick={() => {
                    const values = [...photos];
                    values.splice(
                      values.findIndex((value) => value.id === p.id),
                      1,
                    );
                    setPhotos(values);
                  }}>
                  <CloseIcon />
                </IconButton>
              }
            />
            <CardMedia
              component="img"
              height="160"
              image={URL.createObjectURL(p.photo)}
              alt={p.photo?.name}
            />
          </Card>
        </Grid>
      );
    });
  }, [photos?.length]);

  return (
    <Box height={{ xs: 'initial', md: '82vh' }}>
      <Stack direction={{ xs: 'column', md: 'row' }} gap={2} mb={2}>
        <Box component={Paper} elevation={2} width={{ xs: 1, md: 0.5 }}>
          <Stack direction="row" flexWrap="wrap" sx={{ p: 3, gap: 2 }}>
            <Box width={{ xs: 1 }}>
              <Typography variant="subtitle1" color="primary">
                Product definition
              </Typography>
            </Box>
            <Box width={{ xs: 1 }}>
              <Stack direction="row" alignItems="center">
                <Typography variant="subtitle2">
                  Product Status&emsp;
                </Typography>
                <FormControlLabel
                  label={isActive ? 'Active' : 'Inactive'}
                  value="active"
                  control={
                    <Switch
                      color="primary"
                      checked={isActive}
                      onChange={() => setIsActive(!isActive)}
                    />
                  }
                />
              </Stack>
            </Box>
            <Box width={{ xs: 1 }}>
              <Typography variant="subtitle2">Company</Typography>
              <Autocomplete
                fullWidth
                size="small"
                disableClearable
                options={companiesList}
                getOptionLabel={(option) => option?.productCompany || ''}
                isOptionEqualToValue={(option, value) =>
                  value?.id === option?.id
                }
                onChange={(event, newValue) => setProductCompany(newValue)}
                value={productCompany}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Box>
            {['Super Admin'].includes(role) ? (
              <Box width={{ xs: 1 }}>
                <Stack direction="row" alignItems="center">
                  <Typography variant="subtitle2">
                    Own Company Product&emsp;
                  </Typography>
                  <FormControlLabel
                    label={isOwn ? 'Yes' : 'No'}
                    control={
                      <Switch
                        color="primary"
                        checked={isOwn}
                        onChange={() => setIsOwn(!isOwn)}
                      />
                    }
                  />
                </Stack>
              </Box>
            ) : null}
            <Box width={{ xs: 1 }}>
              <Typography variant="subtitle2">Category</Typography>
              <Autocomplete
                fullWidth
                size="small"
                disableClearable
                options={categoriesList}
                getOptionLabel={(option) => option?.productCategory || ''}
                isOptionEqualToValue={(option, value) =>
                  value?.id === option?.id
                }
                onChange={(event, newValue) => setProductCategory(newValue)}
                value={productCategory}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Box>
            <Box width={{ xs: 1 }}>
              <Typography variant="subtitle2">Name</Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Box>
            <Box width={{ xs: 1 }}>
              <Typography variant="subtitle2">Code</Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Box>
            <Box width={{ xs: 1 }}>
              <Typography variant="subtitle2">Description</Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                type="text"
                multiline
                rows={5}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Box>
          </Stack>
        </Box>
        <Box component={Paper} elevation={2} width={{ xs: 1, md: 0.5 }}>
          <Stack direction="row" flexWrap="wrap" sx={{ p: 3, gap: 2 }}>
            <Box width={{ xs: 1 }}>
              <Typography variant="subtitle1" color="primary">
                Package info
              </Typography>
            </Box>
            <Box width={{ xs: 1, lg: '48%' }}>
              <Typography variant="subtitle2">Quantity per carton</Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                type="number"
                onWheel={(e) => e.target.blur()}
                value={quantityPerCarton}
                onChange={(e) => setQuantityPerCarton(e.target.value)}
              />
            </Box>
            <Box width={{ xs: 1, lg: '48%' }}>
              <Typography variant="subtitle2">Unit of measurement</Typography>
              <FormControl fullWidth>
                <Select
                  size="small"
                  value={unitOfMeasurement}
                  onChange={(e) => setUnitOfMeasurement(e.target.value)}>
                  {Object.entries(unitLabels).map(([value, label]) => (
                    <MenuItem key={label} value={value}>
                      {label} ({value})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box width={{ xs: 1, lg: '48%' }}>
              <Typography variant="subtitle2">
                {unitLabels[unitOfMeasurement] || 'Units'} per packet
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                type="number"
                onWheel={(e) => e.target.blur()}
                value={unitPerPacket}
                onChange={(e) => setUnitPerPacket(e.target.value)}
              />
            </Box>
            <Box width={{ xs: 1, lg: '48%' }}>
              <Typography variant="subtitle2">Net weight per packet</Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                type="number"
                onWheel={(e) => e.target.blur()}
                value={weightPerPacket}
                onChange={(e) => setWeightPerPacket(e.target.value)}
              />
            </Box>
            <Box width={{ xs: 1 }}>
              <Typography variant="subtitle1" color="primary">
                Price info
              </Typography>
            </Box>
            <Box width={{ xs: 1, lg: '48%' }}>
              <Typography variant="subtitle2">
                DSR price per
                <b>
                  <i> carton</i>
                </b>
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                type="number"
                onWheel={(e) => e.target.blur()}
                value={dsrPrice}
                onChange={(e) => setDsrPrice(e.target.value)}
              />
            </Box>
            <Box width={{ xs: 1, lg: '48%' }}>
              <Typography variant="subtitle2">
                Dealer price per
                <b>
                  <i> carton</i>
                </b>
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                type="number"
                onWheel={(e) => e.target.blur()}
                value={dealerPrice}
                onChange={(e) => setDealerPrice(e.target.value)}
              />
            </Box>
            <Box width={{ xs: 1, lg: '48%' }}>
              <Typography variant="subtitle2">
                Trade price per
                <b>
                  <i> carton</i>
                </b>
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                type="number"
                onWheel={(e) => e.target.blur()}
                value={tradePrice}
                onChange={(e) => setTradePrice(e.target.value)}
              />
            </Box>
            <Box width={{ xs: 1, lg: '48%' }}>
              <Typography variant="subtitle2">
                Max retail price per packet
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                type="number"
                onWheel={(e) => e.target.blur()}
                value={maxRetailPrice}
                onChange={(e) => setMaxRetailPrice(e.target.value)}
              />
            </Box>
            <Box width={{ xs: 1 }}>
              <Typography variant="subtitle1" color="primary">
                Offer info
              </Typography>
            </Box>
            <Box width={{ xs: 1, lg: '48%' }}>
              <Typography variant="subtitle2">Offer type</Typography>
              <FormControl fullWidth>
                <Select
                  size="small"
                  value={offerType}
                  onChange={(e) => setOfferType(e.target.value)}>
                  {Object.entries(offerTypeLabels).map(([value, label]) => (
                    <MenuItem key={label} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {offerType === 'free' ? (
              <Box width={{ xs: 1, lg: '48%' }}>
                <Typography variant="subtitle2">
                  Free items per carton
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  value={freeItemsPerCarton}
                  onChange={(e) => setFreeItemsPerCarton(e.target.value)}
                />
              </Box>
            ) : null}
            <Box width={{ xs: 1, lg: '48%' }}>
              <Typography variant="subtitle2">
                Minimum order quantity for offer
              </Typography>
              <TextField
                disabled={offerType !== 'gift'}
                fullWidth
                variant="outlined"
                size="small"
                type="number"
                onWheel={(e) => e.target.blur()}
                value={minimumOrderQuantityForOffer}
                onChange={(e) =>
                  setMinimumOrderQuantityForOffer(e.target.value)
                }
              />
            </Box>
            {offerType === 'gift' ? (
              <Box width={{ xs: 1 }}>
                <Typography variant="subtitle2">
                  Gift item description
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="text"
                  multiline
                  rows={2}
                  value={giftItemDescription}
                  onChange={(e) => setGiftItemDescription(e.target.value)}
                />
              </Box>
            ) : null}
          </Stack>
        </Box>
      </Stack>

      <Box>
        <Paper elevation={2} sx={{ p: 3 }}>
          <Typography variant="subtitle2" sx={{ pb: 2 }}>
            Photos
          </Typography>
          <Grid container spacing={3}>
            {photosPreview?.length > 0 ? photosPreview : oldPhotosPreview}

            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <Box
                sx={{
                  minHeight: 200,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Fab
                  component="label"
                  htmlFor="choose-product-photos"
                  variant="extended"
                  size="medium"
                  aria-label="add photos">
                  <AddAPhotoOutlinedIcon sx={{ mr: 1 }} />
                  Add photos
                </Fab>
                {oldPhotos?.length > 0 ? (
                  <Typography
                    align="center"
                    variant="subtitle2"
                    color="text.secondary">
                    If you add new photos, previously added photos will be
                    replaced by the new ones.
                  </Typography>
                ) : null}
                <input
                  id="choose-product-photos"
                  type="file"
                  accept="image/png, image/jpeg"
                  hidden
                  multiple
                  onChange={(event) => {
                    const files = [...photos];
                    [...event.target.files]?.forEach((file) => {
                      files.push({
                        id: `${Date.now()}${Math.random()}`,
                        photo: file,
                      });
                    });
                    setPhotos(files);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Box sx={{ mt: 3, pb: 8, textAlign: 'center' }}>
        <Button
          disabled={isLoading}
          size="large"
          color="primary"
          variant="contained"
          onClick={handleSubmit}>
          Save product
        </Button>
      </Box>
    </Box>
  );
}
