import BorderColorIcon from '@mui/icons-material/BorderColor';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useQuery } from 'react-query';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmModal from '../../component/ConfirmModal';
import {
  DateRangeColumnFilter,
  MultiSelectColumnFilter,
} from '../../component/Table/SelectColumnFilter';
import CustomTable from '../../component/Table/Table';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import useHeader from '../../hooks/useHeader';
import roundToDigit from '../../utils/roundToDigit';

function SrReportDetails() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const { setHeader } = useHeader();
  const { role, user, userId, Admins } = useAuth();

  React.useEffect(() => {
    setHeader({ title: 'SR Report' });
  }, []);

  const [entryStartDate, setEntryStartDate] = React.useState(
    dayjs().subtract(1, 'month').startOf('month'),
  );
  const [entryEndDate, setEntryEndDate] = React.useState(
    dayjs().endOf('month'),
  );

  const [deleteShow, setDeleteShow] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState('');

  const handleAdd = () => {
    navigate('/sr-report-entries/add');
  };
  const handleEdit = (id) => {
    navigate(`/sr-report-entries/edit/${id}`);
  };
  const handleDeleteSelection = (id) => {
    setDeleteId(id);
    setDeleteShow(true);
  };
  const handleDelete = () => {
    axiosPrivate
      .delete(`/api/v1/sales-activity/${deleteId}`)
      .then((res) => {
        if (res.status === 204) {
          toast.success('Document was deleted');
          refetch();
        }
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      })
      .finally(() => {
        setDeleteId('');
        setDeleteShow(false);
      });
  };

  const { data, isLoading, error, isError, refetch } = useQuery(
    ['sales-activity', entryStartDate, entryEndDate],
    () => {
      let url =
        '/api/v1/sales-activity?populate=yes&limit=none&sort=-reportDate';

      if (!['Super Admin', ...Admins].includes(role)) {
        url += `&parentTerritory=${user?.employee?.territory?.id}`;
      }
      if (entryStartDate?.isValid()) {
        url += `&createdAt[gte]=${entryStartDate.toISOString()}`;
      }
      if (entryEndDate?.isValid()) {
        url += `&createdAt[lte]=${entryEndDate.toISOString()}`;
      }

      return axiosPrivate.get(url).then((res) => {
        const info = [];

        res?.data?.data?.forEach((d) => {
          const orderValuePerMemo = roundToDigit(d?.orderValue / d?.memoCount);
          const linePerCall = roundToDigit(d?.orderQuantity / d?.memoCount);
          const aveSkuValuePerMemo = roundToDigit(
            orderValuePerMemo / linePerCall,
          );
          const strikeRate = roundToDigit(
            (d?.memoCount / d?.outletCount) * 100,
          );
          const orderExecutionRate = roundToDigit(
            (d?.deliveryValue / d?.orderValue) * 100,
          );

          const i = {
            ...d,
            orderValuePerMemo,
            linePerCall,
            aveSkuValuePerMemo,
            strikeRate,
            orderExecutionRate,
          };

          info.push(i);
        });

        return info;
      });
    },
  );

  const entryDateRangeFilter = React.useMemo(
    () => (
      <Stack>
        <input
          type="date"
          value={entryStartDate?.format('YYYY-MM-DD') || ''}
          onChange={(e) => {
            setEntryStartDate(dayjs(e.target.value).startOf('date'));
          }}
        />
        <input
          type="date"
          value={entryEndDate?.format('YYYY-MM-DD') || ''}
          onChange={(e) => {
            setEntryEndDate(dayjs(e.target.value).endOf('date'));
          }}
        />
      </Stack>
    ),

    [entryStartDate, entryEndDate],
  );

  const COLUMNS = React.useMemo(
    () => [
      {
        Header: () => null,
        id: 'expander',
        hidePrint: true,
        Cell: ({ row }) => (
          <>
            {row.isExpanded ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </>
        ),
      },
      {
        Header: 'Entered By',
        accessor: 'recordedBy.nameCode',
      },
      {
        Header: 'Entry Time',
        accessor: 'createdAt',
        Cell: ({ value }) =>
          value ? dayjs(value).format('DD/MM/YYYY hh:mm A') : null,
        Filter: entryDateRangeFilter,
      },
      {
        Header: 'Report Date',
        accessor: 'reportDate',
        Cell: ({ value }) => (value ? dayjs(value).format('DD/MM/YYYY') : null),
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'SO/SR',
        accessor: 'employee.nameCode',
      },
      {
        Header: 'HQ',
        accessor: 'employee.territory.parentTerritory.name',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Point',
        accessor: 'employee.territory.name',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Route',
        accessor: 'route',
      },
      {
        Header: 'Visited Outlets',
        accessor: 'outletCount',
      },
      {
        Header: 'Order Qty',
        accessor: 'orderQuantity',
        Cell: ({ value }) => (value ? `${value} ctn` : null),
        aggregate: 'sum',
      },
      {
        Header: 'Order Value',
        accessor: 'orderValue',
        Cell: ({ value }) => (value ? `${value} Tk` : null),
        aggregate: 'sum',
      },
      {
        Header: 'Memos',
        accessor: 'memoCount',
        aggregate: 'sum',
      },
      {
        Header: 'Delivery Qty',
        accessor: 'deliveryQuantity',
        Cell: ({ value }) => (value ? `${value} ctn` : null),
        aggregate: 'sum',
      },
      {
        Header: 'Delivery Value',
        accessor: 'deliveryValue',
        Cell: ({ value }) => (value ? `${value} Tk` : null),
        aggregate: 'sum',
      },
      {
        Header: 'Lifting Qty',
        accessor: 'liftingQuantity',
        Cell: ({ value }) => (value ? `${value} ctn` : null),
        aggregate: 'sum',
      },
      {
        Header: 'Stock Qty',
        accessor: 'stockQuantity',
        Cell: ({ value }) => (value ? `${value} ctn` : null),
      },
      {
        Header: 'Ave. SKU Per Memo',
        accessor: 'aveSkuValuePerMemo',
      },
      {
        Header: 'Order Value Per Memo',
        accessor: 'orderValuePerMemo',
      },
      {
        Header: 'Line Per Call',
        accessor: 'linePerCall',
      },
      {
        Header: 'Strike Rate',
        accessor: 'strikeRate',
        Cell: ({ value }) => (value ? `${value}%` : null),
      },
      {
        Header: 'Order Execution Rate',
        accessor: 'orderExecutionRate',
        Cell: ({ value }) => (value ? `${value}%` : null),
      },
      {
        Header: 'Action',
        id: 'Action',
        hidePrint: true,
        Cell: ({ row }) => {
          if (
            (role !== 'Admin' && ['Super Admin', ...Admins].includes(role)) ||
            row?.original?.recordedBy?.id === userId
          ) {
            return (
              <Stack direction="row" alignItems="center" spacing={2}>
                <IconButton
                  onClick={() => handleEdit(row?.original?.id)}
                  className="Table_icon_btn">
                  <BorderColorIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleDeleteSelection(row?.original?.id)}
                  className="Table_icon_btn delete">
                  <DeleteIcon />
                </IconButton>
              </Stack>
            );
          }

          return null;
        },
      },
    ],
    [entryDateRangeFilter],
  );

  if (isError) {
    expired(error);
  }

  if (pathname !== '/sr-report-entries') {
    return <Outlet context={[refetch]} />;
  }

  return (
    <>
      <CustomTable
        columns={COLUMNS}
        data={data || []}
        isLoading={isLoading}
        noWrap
        onAddButtonClick={handleAdd}
        addButtonTooltip="Add SR Report"
        filename="Sr_Report"
        expandRow={ExpandRowInfo}
        hasDrawer
      />

      <ConfirmModal
        title="Are you sure?"
        subtitle="You won't be able to revert this!"
        show={deleteShow}
        onSave={handleDelete}
        onHide={() => {
          setDeleteId('');
          setDeleteShow(false);
        }}
        actionButtonLabel="Delete"
        actionButtonColor="error"
      />
    </>
  );
}

export default SrReportDetails;

function ExpandRowInfo({ data }) {
  console.log(data);
  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{
          table: { th: { fontWeight: 700, p: 1 }, td: { p: 1 } },
        }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th">Enter By</TableCell>
              <TableCell>{data?.recordedBy?.nameCode}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Enter Time</TableCell>
              <TableCell>
                {data?.createdAt
                  ? dayjs(data?.createdAt).format('DD/MM/YYYY hh:mm A')
                  : null}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Report Date</TableCell>
              <TableCell>
                {data?.reportDate
                  ? dayjs(data?.reportDate).format('DD/MM/YYYY hh:mm A')
                  : null}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">SO/SR</TableCell>
              <TableCell>{data?.employee?.nameCode}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">HQ-Point-Route</TableCell>
              <TableCell>
                {data?.employee.territory.parentTerritory.name} -{' '}
                {data?.employee.territory.name} - {data?.route}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ p: 1 }}></Box>
      <TableContainer
        component={Paper}
        sx={{
          table: { th: { fontWeight: 700, p: 1 }, td: { p: 1 } },
        }}>
        <Table>
          <TableHead></TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th">Visited Outlets</TableCell>
              <TableCell>{data?.outletCount}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Order Quantity</TableCell>
              <TableCell>{data?.orderQuantity} ctn</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Order Value</TableCell>
              <TableCell>{data?.orderValue} tk</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Memos</TableCell>
              <TableCell>{data?.memoCount}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Delivery Quantity</TableCell>
              <TableCell>{data?.deliveryQuantity} ctn</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Delivery Value</TableCell>
              <TableCell>{data?.deliveryValue} tk</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Lifting Quantity</TableCell>
              <TableCell>{data?.liftingQuantity} ctn</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Stock Quantity</TableCell>
              <TableCell>{data?.stockQuantity} ctn</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Ave. SKU Per Memo</TableCell>
              <TableCell>{data?.aveSkuValuePerMemo}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Order Value Per Memo</TableCell>
              <TableCell>{data?.orderValuePerMemo}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Line Per Call</TableCell>
              <TableCell>{data?.linePerCall}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Strike Rate</TableCell>
              <TableCell>{data?.strikeRate}%</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Order Execution Rate</TableCell>
              <TableCell>{data?.orderExecutionRate}%</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
